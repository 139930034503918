import React from 'react';
import './css/DiceRoller.css'; // Import your CSS

class DiceRoller extends React.Component {


    render() {

        const diceOne = this.props.diceValue;
        const rolling = this.props.rolling


        // console.log(rolling)
        return (

            <div>
                {rolling ?

                    <div id='dice1' className={`dice dice-one show-${diceOne}`}>
                        <div id="dice-one-side-one" className='side one'>
                            <div className="dotDice one-1"></div>
                        </div>
                        <div id="dice-one-side-two" className='side two'>
                            <div className="dotDice two-1"></div>
                            <div className="dotDice two-2"></div>
                        </div>
                        <div id="dice-one-side-three" className='side three'>
                            <div className="dotDice three-1"></div>
                            <div className="dotDice three-2"></div>
                            <div className="dotDice three-3"></div>
                        </div>
                        <div id="dice-one-side-four" className='side four'>
                            <div className="dotDice four-1"></div>
                            <div className="dotDice four-2"></div>
                            <div className="dotDice four-3"></div>
                            <div className="dotDice four-4"></div>
                        </div>
                        <div id="dice-one-side-five" className='side five'>
                            <div className="dotDice five-1"></div>
                            <div className="dotDice five-2"></div>
                            <div className="dotDice five-3"></div>
                            <div className="dotDice five-4"></div>
                            <div className="dotDice five-5"></div>
                        </div>
                        <div id="dice-one-side-six" className='side six'>
                            <div className="dotDice six-1"></div>
                            <div className="dotDice six-2"></div>
                            <div className="dotDice six-3"></div>
                            <div className="dotDice six-4"></div>
                            <div className="dotDice six-5"></div>
                            <div className="dotDice six-6"></div>
                        </div>
                    </div>
                    :
                    <div id='dice1' className={`dice dice-one`}>
                        {diceOne === 1 && <div id="dice-one-side-one" className='side one'>
                            <div className="dotDice one-1"></div>
                        </div>
                        }
                        {diceOne === 2 && <div id="dice-one-side-two" className='side two'>
                            <div className="dotDice two-1"></div>
                            <div className="dotDice two-2"></div>
                        </div>
                        }
                        {diceOne === 3 && <div id="dice-one-side-three" className='side three'>
                            <div className="dotDice three-1"></div>
                            <div className="dotDice three-2"></div>
                            <div className="dotDice three-3"></div>
                        </div>
                        }
                        {diceOne === 4 && <div id="dice-one-side-four" className='side four'>
                            <div className="dotDice four-1"></div>
                            <div className="dotDice four-2"></div>
                            <div className="dotDice four-3"></div>
                            <div className="dotDice four-4"></div>
                        </div>
                        }
                        {diceOne === 5 && <div id="dice-one-side-five" className='side five'>
                            <div className="dotDice five-1"></div>
                            <div className="dotDice five-2"></div>
                            <div className="dotDice five-3"></div>
                            <div className="dotDice five-4"></div>
                            <div className="dotDice five-5"></div>
                        </div>
                        }
                        {diceOne === 6 && <div id="dice-one-side-six" className='side six'>
                            <div className="dotDice six-1"></div>
                            <div className="dotDice six-2"></div>
                            <div className="dotDice six-3"></div>
                            <div className="dotDice six-4"></div>
                            <div className="dotDice six-5"></div>
                            <div className="dotDice six-6"></div>
                        </div>
                        }
                    </div>
                }

            </div>


        );
    }
}

export default DiceRoller;
