import * as React from "react";
import LanguageContext from "./hooks/LanguageContext";
import parse from 'html-react-parser';
import ModalInstruction from "./ModalInstruction";
const OtherCommingSoon = ({ question, handleNextClick }) => {
  const { language } = React.useContext(LanguageContext);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(question)
  return (
    <>
      <div className='justify-content-end d-flex' >
        {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
        <div onClick={handleShow}>
          <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' alt="instructions.png"/>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="sectionsBox">
            <p className="mb-0 text-center">
              {/* {language === "english" ? "2-factor authentication" : "टू-फैक्टर ऑथेंटिकेशन"} */}
              {/* {question?.title[language]} */}
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          <div className="contentbox mb-2 mt-3">
            <div className="content_safty ">
              {/* {parse(question?.description[language])} */}
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-5 col-md-12 my-4">
          <div className="bg-light" style={{ height: 150, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <h1 style={{}}>Comming Soon</h1>
            {/* <div onClick={() => { navigate('/home') }} className="text-primary" >Go to Home</div> */}
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 ">
          <div className="contentbox mb-2 mt-3">
            <div className="content_safty ">

            {language==="english" ? "Select the right buttons and set up 2-factor authentication for this Instagram account. Go ahead and make your choices to make this account super secure!e" : "सही बटनों को चुनें और इस इंस्टाग्राम खाते में  2 - फैक्टर ऑथेंटिकेशन लगाएं। एक-एक कर बटन चुनें और इस खाते को और भी सुरक्षित बनाएं !"}

            </div>
          </div>
        </div>
        </div>
      <div className="d-flex justify-content-center">
        <div>
          <img src={question.src[language]} useMap={question.usemap} />
          <map name={question.map.name}>
            <area alt={question.map.area[0].title} title={question.map.area[0].title} onClick={handleNextClick} coords={question.map.area[0].coords[language]} shape={question.map.area[0].shape} />
          </map>


        </div>
      </div>
      */}

      {/* <ModalInstruction show={show} handleClose={handleClose} inst={parse(question?.instruction[language])} /> */}

    </>
  )
};
export default OtherCommingSoon;
