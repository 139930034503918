
import { createContext } from "react";


const initialState = {
    language: "english",
    setLanguage: () => {}
  };

const LanguageContext = createContext(initialState);

export default LanguageContext