import React, { Component } from "react";
import "./css/buttons.css"

const Dice = (props) => {
  return (
    <div>
      <button className="button roll-dice" type="button" onClick={props.diceValue} disabled={props.rolling ? true : false} style={{ backgroundColor: props.rolling ? "#c8c8c8" : "#792110", color: props.rolling ? "#454545" : "#ffffff" }}>Roll the Dice</button>
    </div>
  )
}


export default Dice;
