import { useContext } from "react";
import LanguageContext from "./hooks/LanguageContext";

const QuizGuid = () => {
    const { language } = useContext(LanguageContext);
    return (
        <div className="row mt-2">
            <div className="d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008000" className="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg> <span className="guidtext">{language === "english" ? "Completed" : "पूरा हो गया"}</span>

                <div className="ms-5"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#454545" className="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                </svg> <span className="guidtext">{language === "english" ? "Not Completed" : "पूरा नहीं हुआ"}</span>
                </div>
            </div>
        </div>
    )
}

export default QuizGuid