import Header from "../components/Header"


const NotFound = () => {
    return (
        <div id="body_bg">

            <Header page_title={"error"} />
            <div className="row justify-content-center">
                <div className="col-xl-5 col-md-12">
                    <div className="d-flex align-items-center justify-content-center vh-100 bg-primary">
                        <h1 className="display-1 fw-bold text-white">404</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound