// src/App.js
import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import Ball from "../components/draganddrop/Ball"
import Bucket from "../components/draganddrop/Bucket"
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd';
import LanguageContext from './hooks/LanguageContext';
import parse from 'html-react-parser';
import ModalInstruction from './ModalInstruction';


const QuizMultiSelectDragDrop = ({ question, isMarked, rightBucket, setRightBucket, wrongBucket, setWrongBucket }) => {
    const { language } = useContext(LanguageContext);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    // console.log("question", question?.options)
    const [balls, setBalls] = useState(question?.options)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    // const [balls, setBalls] = useState([
    //     { id: 1, ballStatus: false, isRight: false, title: { english: "Anushka12", hindi: "गुल@ब!ज#45" } },
    //     { id: 2, ballStatus: false, isRight: false, title: { english: "Abc123", hindi: "लाख@भले235" } },
    //     { id: 3, ballStatus: false, isRight: true, title: { english: "Tru3Blu3$ky", hindi: "मोबाइल9876" } },
    //     { id: 4, ballStatus: false, isRight: false, title: { english: "Password", hindi: "अनुष्का12" } },
    //     { id: 5, ballStatus: false, isRight: true, title: { english: "Qwerty!234", hindi: "अबीसी123" } },
    //     { id: 6, ballStatus: false, isRight: true, title: { english: "Fire&Ice$20", hindi: "च@नद्रू@1" } },

    // ]);



    const handleDrop = (id) => {
        let remainingBalls = balls.filter((ball) => ball.id == id);
        let droppedBall = balls.find((ball) => ball.id === id);
        // console.log(remainingBalls)
        if (droppedBall.isRight) {
            setRightBucket((prev) => [...prev, droppedBall]);
        } else {
            setWrongBucket((prev) => [...prev, droppedBall]);
        }

        switch (droppedBall.id) {
            case 1:
                const updatedBalls1 = [...balls];
                updatedBalls1[0].ballStatus = true;
                // console.log(updatedBalls1)
                setBalls(updatedBalls1);
                break;
            case 2:
                const updatedBalls2 = [...balls];
                updatedBalls2[1].ballStatus = true;
                // console.log(updatedBalls2)
                setBalls(updatedBalls2);
                break;
            case 3:
                const updatedBalls3 = [...balls];
                updatedBalls3[2].ballStatus = true;
                // console.log(updatedBalls3)
                setBalls(updatedBalls3);
                break;
            case 4:
                const updatedBalls4 = [...balls];
                updatedBalls4[3].ballStatus = true;
                // console.log(updatedBalls4)
                setBalls(updatedBalls4);
                break;
            case 5:
                const updatedBalls5 = [...balls];
                updatedBalls5[4].ballStatus = true;
                // console.log(updatedBalls5)
                setBalls(updatedBalls5);
                break;
            case 6:
                const updatedBalls6 = [...balls];
                updatedBalls6[5].ballStatus = true;
                // console.log(updatedBalls6)
                setBalls(updatedBalls6);
                break;
        }
    };


    useEffect(() => {
        if (isMarked === 1) {


            setBalls([
                { id: 1, ballStatus: true, isRight: true, title: { english: "Tru3Blu3$ky", hindi: "गुल@ब!ज#45" } },
                { id: 2, ballStatus: true, isRight: false, title: { english: "Qwerty!234", hindi: "लाख@भले235" } },
                { id: 3, ballStatus: true, isRight: false, title: { english: "Fire&Ice$20", hindi: "च@नद्रू@1" } },
                { id: 4, ballStatus: true, isRight: true, title: { english: "Anushka12", hindi: "अनुष्का12" } },
                { id: 5, ballStatus: true, isRight: false, title: { english: "Abc123", hindi: "अबीसी123" } },
                { id: 6, ballStatus: true, isRight: true, title: { english: "Password", hindi: "मोबाइल9876" } },

            ]);


            setRightBucket([
                { id: 1, ballStatus: true, isRight: true, title: { english: "Tru3Blu3$ky", hindi: "गुल@ब!ज#45" } },
                { id: 2, ballStatus: true, isRight: false, title: { english: "Qwerty!234", hindi: "लाख@भले235" } },
                { id: 3, ballStatus: true, isRight: false, title: { english: "Fire&Ice$20", hindi: "च@नद्रू@1" } },

            ]);
            setWrongBucket([
                { id: 4, ballStatus: true, isRight: true, title: { english: "Anushka12", hindi: "अनुष्का12" } },
                { id: 5, ballStatus: true, isRight: false, title: { english: "Abc123", hindi: "अबीसी123" } },
                { id: 6, ballStatus: true, isRight: true, title: { english: "Password", hindi: "मोबाइल9876" } },
            ])
        }
    }, [isMarked])
    // console.log("rightBucket",rightBucket)
    // console.log("wrongBucket",wrongBucket)
    // console.log("balls",balls)

    return (
        <DndProvider backend={screenSize.width >= 921 ? HTML5Backend : TouchBackend}>
            <>
                <div className='justify-content-end d-flex' >
                    {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                    <div onClick={handleShow}>
                        <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="sectionsBox">
                            <p className="mb-0 text-center">{question?.title[language]}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <div className="contentbox mb-2 mt-3">
                            <div className="content_safty ">
                                {parse(question?.description[language])}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="my-4" style={{ display: "flex", justifyContent: "center", marginBottom: 20,  }}><iframe toolbar={0} src={require("../assets/pdf/strong_week_english.pdf#toolbar=0") } width="425" height="600" frameborder="0" /></div> */}
                <section className="my-3">
                    <div className="container-fluid">
                        <div className='d-flex align-item-center justify-content-between'>
                            <div style={{}}>
                                {balls.map((ball) => {
                                    // console.log(ball)
                                    return (
                                        <Ball key={ball.id} id={ball.id} isRight={ball.isRight} ballStatus={ball.ballStatus} text={ball.title[language]} />
                                    )
                                })}
                            </div>
                            <div className="d-flex align-items-center">
                                <div style={{ alignContent: "center" }}>
                                    <Bucket type="RIGHT_BALL" onDrop={handleDrop}>
                                        {/* {rightBucket.map((ball) => (
                            <Ball key={ball.color} color={ball.color} isRight={ball.isRight} />
                        ))} */}
                                        <div style={{ display: "contents" }} className='text-center' >
                                            {rightBucket.length === 3 ? <img src={require("../assets/images/DCP2-gif.gif")} width={70}
                                            // style={{ transform: "rotateZ(65deg)", transition: "2s ease"  }}
                                            /> : <img src={require("../assets/images/bowlcheck.png")} width={70} />}
                                            <p className='text-center'>{language === "english" ? "Strong" : "मज़बूत"} </p>
                                        </div>
                                    </Bucket>
                                    <Bucket type="WRONG_BALL" onDrop={handleDrop}>
                                        {/* {wrongBucket.map((ball) => (
                            <Ball key={ball.color} color={ball.color} isRight={ball.isRight} />
                        ))} */}
                                        <div style={{ display: "contents" }} className='text-center' >
                                            {wrongBucket.length === 3 ? <img
                                                src={require("../assets/images/DCP2-gif-2.gif")} width={70}
                                            //  style={{ transform: "rotateZ(65deg)", transition: "2s ease"  }}
                                            />
                                                : <img src={require("../assets/images/bowlcheck.png")} width={70} />}
                                            <p className='text-center '>{language === "english" ? "Weak" : "कमज़ोर"}</p>
                                        </div>
                                    </Bucket>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
            </>
        </DndProvider>
    );
};

export default QuizMultiSelectDragDrop;
