const LoadingSnip = () => {
    const height = window.innerHeight;

    return (
        <div className="d-flex justify-content-center align-items-center " style={{ height: height - 240 }}>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>

    )
}

export  default LoadingSnip