const position =  {
 0: 0,
 1: 1,
 2: 2,
 3: 24,
 4: 4,
 5: 5,
 6: 6,
 7: 7,
 8: 8,
 9: 9,
 10: 10,
 11: 11,
 12: 70,
 13: 13,
 14: 14,
 15: 15,
 16: 6,
 17: 17,
 18: 18,
 19: 19,
 20: 20,
 21: 63,
 22: 4,
 23: 23,
 24: 24,
 25: 25,
 26: 26,
 27: 27,
 28: 28,
 29: 29,
 30: 30,
 31: 31,
 32: 13,
 33: 33,
 34: 53,
 35: 35,
 36: 36,
 37: 37,
 38: 38,
 39: 39,
 40: 40,
 41: 41,
 42: 42,
 43: 43,
 44: 44,
 45: 45,
 46: 84,
 47: 47,
 48: 48,
 49: 49,
 50: 50,
 51: 51,
 52: 52,
 53: 53,
 54: 54,
 55: 55,
 56: 56,
 57: 57,
 58: 79,
 59: 59,
 60: 19,
 61: 61,
 62: 62,
 63: 63,
 64: 26,
 65: 65,
 66: 66,
 67: 96,
 68: 68,
 69: 69,
 70: 70,
 71: 92,
 72: 28,
 73: 73,
 74: 74,
 75: 75,
 76: 76,
 77: 77,
 78: 78,
 79: 79,
 80: 80,
 81: 81,
 82: 82,
 83: 83,
 84: 84,
 85: 85,
 86: 86,
 87: 55,
 88: 88,
 89: 89,
 90: 90,
 91: 91,
 92: 92,
 93: 93,
 94: 94,
 95: 95,
 96: 96,
 97: 97,
 98: 2,
 99: 99,
 100: 100
}



 export default position;
