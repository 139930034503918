import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faEye, faFilePdf, faFileVideo, faImage, faUpRightFromSquare, faVideo } from "@fortawesome/free-solid-svg-icons"
import MyVerticallyCenteredModalResource from "../components/MyVerticallyCenteredModalResource"
import Constants from "../common/Constants"
import { useAuth } from "../providers/AuthContext"

const API_RESOURCES = `${Constants.BASE_URL}/appresources`;
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const Resources = () => {
    const { user, logout: authLogout, setMyPoints } = useAuth(); // Use useAuth
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const { language } = useContext(LanguageContext);
    const [module, setModule] = useState(null)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalItem, setModalItem] = useState('');
    const [modalItemType, setModalItemType] = useState('');

    useEffect(() => {
        fetchData(`${API_RESOURCES}?access_token=${user?.token}`, setModule);
    }, [user?.token]);

    const fetchData = (url, setData) => {
        setLoading(true);
        axios
            .get(url)
            .then((response) => {
                console.log(response.data);
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    setData(response.data);
                    setLoading(false);
                }

            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const openModal = (title, item, type) => () => {
        console.log(title)
        setModalTitle(title);
        setModalItem(item);
        setModalItemType(type)
        setModalShow(true);
    };

    useEffect(() => {
        setLoading(true)
        let newUrl = `${API_STUDENT_PROGRESS}?access_token=${user?.token}`
        axios.post(newUrl).then((response) => {
            if (response.data.message === "Invalid Access Token") {
                authLogout()
                navigate("/");
            } else {
                // console.log(response.data)
                setMyPoints(response.data.student_summary.points)
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        });
    }, [])

    return (
        <>
            <div id="body_bg">
                <Header page_title={language === "english" ? "Learn More" : "और सीखें"} />
                <LanguageSet />
                <div className="container-fluid px-0">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-12">
                            <section className="my-3">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="sectionsBox">
                                                <p className="mb-0 text-center">
                                                    {language === "english" ? "Learn More" : "और सीखें"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 ">
                                            <div className="contentbox m-1">
                                                <div className="content_safty ">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <div className="row my-3">
                                            <div className="col-12">
                                                {module?.resource_summary?.map((item, index) => (
                                                    <div className="annoucemnet_bx mb-2" key={index}>
                                                        <div className="row">
                                                            <div className="col-7 d-flex justify-content-between align-items-center">

                                                                <a className="leftannou ms-5" style={{ textDecorationColor: "#2f4672" }} href={item.external_link[language]} target='_blank' rel='noopener noreferrer'>
                                                                    <p className="mb-0" style={{ color: "#2f4672" }}>{item.title[language]}</p>

                                                                </a>

                                                            </div>
                                                            <div className="col-1 justify-content-between align-items-center d-flex">
                                                            </div>
                                                            <div className="col-1 justify-conten-right align-items-center d-flex" onClick={openModal("Discreption", item.description[language], "")}>
                                                                <FontAwesomeIcon icon={faEye} size="sm" color="#002859" />
                                                            </div>
                                                     
                                                            <div className="col-1 justify-content-right align-items-center d-flex">
                                                          
                                                                    <a  href={item.external_link[language]} target='_blank' download className="me-5">
                                                                        <FontAwesomeIcon icon={faVideo} size="sm" color="#002859" />
                                                                    </a>
                                                                
                                                            </div>
                                                            <div className="col-1 justify-content-between align-items-center d-flex">
                                                                <div className="rightannou">
                                                                    <a className="leftannou " style={{ textDecorationColor: "#2f4672" }} href={item.pdf_path[language]} target='_blank' rel='noopener noreferrer'>
                                                                    <FontAwesomeIcon icon={faFilePdf} size="sm" color="#002859" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-1 justify-content-between align-items-center d-flex">
                                                                <div className="rightannou">
                                                                    <a className="leftannou " style={{ textDecorationColor: "#2f4672" }} href={item.image_path[language]} target='_blank' rel='noopener noreferrer'>
                                                                    <FontAwesomeIcon icon={faImage} size="sm" color="#002859" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                       
                                                        </div>
                                                    </div>

                                                ))
                                                }

                                            </div>
                                        </div>
                                        <MyVerticallyCenteredModalResource
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            title={modalTitle}
                                            imageLink={modalItem}
                                            modalItemType={modalItemType}
                                        />
                                    </>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resources