import React, {Component} from "react"
import "./css/players.css"

const Player1 = () => {
  return (
    <div className="player" id="one"></div>
  )
}

export default Player1
