import { useContext, useEffect, useState } from "react";
import LanguageContext from "./hooks/LanguageContext";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faDownload } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ModalInstruction from "./ModalInstruction";

const ResultTwo = ({ question, answer, showResult2, setShowResult2 }) => {
    // console.log(question)
    // console.log(answer)
    const { language } = useContext(LanguageContext);

    const [totalMarked, setTotalMarked] = useState(0)
    const [totalMarkedStranger, setTotalMarkedStranger] = useState(0)
    const handleClose = () => setShowResult2(false);
    const handleShow = () => setShowResult2(true);

    const [showInst, setShowInst] = useState(false);

    const handleCloseInst = () => setShowInst(false);
    const handleShowInst = () => setShowInst(true);


    useEffect(() => {
        let sum = 0;
        let total = answer?.course_module_item[0]?.result_online?.length;
        let markedItem = answer?.course_module_item[0]?.result_online?.map((item) => {
            if (Number(item?.online?.answer_marked?.id) === Number(item?.online?.answer_correct?.id)) {
                return item?.online?.answer_marked?.id
            } else if (Number(item?.online?.answer_marked?.id) !== Number(item?.online?.answer_correct?.id)) {
                return 0
            } else {
                return 0
            }
        })
        if (markedItem) {
            markedItem?.forEach((element) => {
                if (element !== 0 && element !== null)
                    sum = sum + 1;
            });
        }
        setTotalMarked(Math.round(sum));
        // console.log(markedItem)
    }, [answer])

    useEffect(() => {
        let sum = 0;
        let total = answer?.course_module_item[0]?.result_online?.length;
        let markedItem = answer?.course_module_item[0]?.result_online?.map((item) => {
            if (Number(item?.stranger?.answer_marked?.id) === Number(item?.stranger?.answer_correct?.id)) {
                return item?.stranger?.answer_marked?.id
            } else if (Number(item?.stranger?.answer_marked?.id) !== Number(item?.stranger?.answer_correct?.id)) {
                return 0
            } else {
                return 0
            }
        })
        if (markedItem) {
            markedItem.forEach((element) => {
                if (element !== 0 && element !== null)
                    sum = sum + 1;
            });
        }
        setTotalMarkedStranger(Math.round(sum));
        // console.log(markedItem)
    }, [answer])

    // console.log(answer)
    // console.log(totalMarkedStranger)
    return (
        <>
            <div className='justify-content-end d-flex' >
                {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                <div onClick={handleShowInst}>
                    <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="sectionsBox">
                        <p className="mb-0 text-center">
                            {question?.title[language]}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 ">
                    {question?.description[language]?.length > 0 && <div className="contentbox mb-2 mt-3">
                        <div className="content_safty ">
                            {parse(question?.description[language])}
                        </div>
                    </div>
                    }
                </div>
            </div>

            <div className="row">
                {answer?.course_module_item[0]?.question_type === "result_two" &&
                    <div className="col-12 ">
                        {answer?.course_module_item[0]?.result_online?.map((item, key) => (<div className="shareEmails my-3" style={{ backgroundColor: "#f3db7380" }} key={key}>
                            <h6 className="text-center text-result">
                                {/* {language === "english" ? "Will You Share Your E-mail address" : "क्या आप अपना ई-मेल पता साझा करेंगे?"} */}
                                {language === "english" ? "Can You Share Your" : "क्या आप अपना "} {item?.online?.question_title[language]}
                            </h6>
                            <div className="row">
                                <div className={Number(question?.course_id) === 1  ? "col-6" : "col-12"}>
                                    <div className="text-center" style={{ color: "#002859", fontSize: "14px" }}>
                                        {language === "english" ? "Online ?" : "ऑनलाइन ?"}
                                    </div>
                                    <div className="text-center"><FontAwesomeIcon icon={faArrowDown} className="text-danger" size="sm" /></div>
                                    <div className="text-center text-danger" style={{ fontSize: "14px" }}>
                                        {item?.online?.answer_marked?.title[language]}
                                    </div>
                                </div>
                                {Number(question?.course_id) === 1 ?
                                    <div className="col-6">
                                        <div className="text-center" style={{ color: "#002859", fontSize: "14px" }}>
                                            {language === "english" ? "With a stranger ?" : "एक अजनबी के साथ ?"}
                                        </div>
                                        <div className="text-center"><FontAwesomeIcon icon={faArrowDown} className="text-danger" size="sm" /></div>
                                        <div className="text-center text-danger" style={{ fontSize: "14px" }}>
                                            {item?.stranger?.answer_marked?.title[language]}
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>
                        ))}
                    </div>
                }
            </div>




            <ModalInstruction show={showInst} handleClose={handleCloseInst} inst={parse(question.instruction[language])} />

            <Modal show={showResult2} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    {language === "english" && ((totalMarked + totalMarkedStranger) >= 22 ? "Great! Now you have a clear understanding of what information should and should not be shared with others in your personal life with another person." : "While creating online accounts, shopping, or exploring new websites, we are often asked for our personal information. Let us be careful while sharing our personal information online. ")}
                    {language === "hindi" && ((totalMarked + totalMarkedStranger) >= 22 ? "शानदार! अब आप समझ चुके हैं कि आपको   जीवन में दूसरों के साथ कौनसी जानकारी बाँटनी चाहिए, और कौनसीनहीं।" : "जब हम ऑनलाइन खाते बनाते हैं, खरीदारी करते हैं या नई वेबसाइटें खोजते हैं, तो अक्सर हमसे हमारी निजी जानकारी की मांग की जाती है। यह जानकारी देते वक्त सावधानी बरतें।  ")}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResultTwo