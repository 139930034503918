import axios from "axios";

// Function to get device type (e.g., desktop, mobile)
// export function getDeviceType() {
//     // Check if the window matches a media query for mobile devices
//     const isMobile = window.matchMedia('(max-width: 768px)').matches;

//     // If true, return 'Mobile'; otherwise, return 'Desktop'
//     return isMobile ? 'Mobile' : 'Desktop';
// }

export function getDeviceType() {
    try {
        const userAgent = navigator.userAgent;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            // If the user agent string contains keywords indicative of a mobile device
            return 'Mobile';
        } else {
            // Otherwise, assume it's a desktop device
            return 'Desktop';
        }
    } catch (error) {
        console.error('Error getting device type:', error);
        return 'Unknown';
    }
}
// Function to get the operating system
export function getOperatingSystem() {
    try {
        const userAgent = window.navigator.userAgent;

        if (userAgent.includes('Windows')) {
            return 'Windows';
        } else if (userAgent.includes('Mac')) {
            return 'Mac';
        } else if (userAgent.includes('Linux')) {
            return 'Linux';
        } else if (userAgent.includes('Android')) {
            return 'Android';
        } else if (userAgent.includes('iOS')) {
            return 'iOS';
        } else {
            return 'Unknown OS';
        }
    } catch (error) {
        console.error('Error getting operating system:', error);
        return 'Unknown OS';
    }
}

// Function to get the browser
export function getBrowser() {
    try {
        const userAgent = window.navigator.userAgent;

        if (userAgent.includes('Chrome')) {
            return 'Chrome';
        } else if (userAgent.includes('Firefox')) {
            return 'Firefox';
        } else if (userAgent.includes('Safari')) {
            return 'Safari';
        } else if (userAgent.includes('Edge') || userAgent.includes('Edg')) {
            return 'Edge';
        } else if (userAgent.includes('Opera')) {
            return 'Opera';
        } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
            return 'Internet Explorer';
        } else {
            return 'Unknown Browser';
        }
    } catch (error) {
        console.error('Error getting browser:', error);
        return 'Unknown Browser';
    }
}

// Function to get the user's IP address (Note: This is just an example, and might not work in all cases)
export async function getUserIPAddress() {
    try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        const userIpAddress = response.data.ip;
        return userIpAddress;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        throw error; // Re-throw the error to handle it elsewhere if needed
    }
}