import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext"
import { useContext, useEffect, useState } from "react"
import axios from "axios"
import MyVerticallyCenteredModal from "../components/MyVerticallyCenteredModal"
import LoadingSnip from "../components/LoadingSnip"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"
import Constants from "../common/Constants"
import { useAuth } from "../providers/AuthContext"
const API_ANNOUNCMENT = `${Constants.BASE_URL}/appannouncement`;
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const Announcement = () => {
    const { user, logout: authLogout, setMyPoints} = useAuth(); // Use useAuth
    const [loading, setLoading] = useState(false);
    const { language } = useContext(LanguageContext);
    const [module, setModule] = useState(null)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalImageLink, setModalImageLink] = useState('');
    const navigate = useNavigate()


    useEffect(() => {
        fetchData(`${API_ANNOUNCMENT}?access_token=${user?.token}`, setModule);
    }, [user?.token]);

    const fetchData = (url, setData) => {
        setLoading(true);
        axios
            .get(url)
            .then((response) => {
                // console.log(response.data);
                setData(response.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };


    const openModal = (title, imageLink) => () => {
        setModalTitle(title);
        setModalImageLink(imageLink);
        setModalShow(true);
    };

    useEffect(() => {
        setLoading(true)
        let newUrl = `${API_STUDENT_PROGRESS}?access_token=${user?.token}`
        axios.post(newUrl).then((response) => {
            if (response.data.message === "Invalid Access Token") {
                authLogout()
                navigate("/");
            } else {
                // console.log(response.data)
                setMyPoints(response.data.student_summary.points)
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        });
    }, [])
    return (
        <>

            <div id="body_bg">
                <Header page_title={language === "english" ? "Announcement" : "सूचनाएँ"} />
                <LanguageSet />
                {loading ? <LoadingSnip />
                    : <>
                        <div className="container-fluid px-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-5 col-md-12">
                                    <section className="my-3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="sectionsBox">
                                                        <p className="mb-0 text-center">
                                                            {language === "english" ? "Announcement" : "घोषणा"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 ">
                                                    <div className="contentbox mb-1">
                                                        <div className="content_safty ">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {module?.announcement_summary?.map((item, index) => (
                                                <div className="row my-3" key={index}>
                                                    <div className="col-12">
                                                        <div className="annoucemnet_bx d-flex justify-content-between align-items-center mb-2">
                                                            <div className="leftannou ms-5" onClick={openModal(item.title[language], item.file_path[language])}>
                                                                <p className="mb-0">
                                                                    {item.title[language]}
                                                                </p>
                                                            </div>
                                                            <div className="rightannou">

                                                                <button className="">
                                                                    <a href={item.file_path[language]} download className="me-5">
                                                                        <FontAwesomeIcon icon={faHandPointRight} size="sm" color="#002859" />
                                                                    </a>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div >
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            title={modalTitle}
                            imageLink={modalImageLink}
                        />
                    </>
                }
            </div>
        </>
    )
}

export default Announcement