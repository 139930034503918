
import { useContext, useEffect, useState } from "react";
import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import LoadingSnip from "../components/LoadingSnip";
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";

const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const MyProgress = () => {
    const { user, logout: authLogout, setMyPoints} = useAuth(); // Use useAuth
    const { language } = useContext(LanguageContext);
    const [loading, setLoading] = useState(false);
    const [progres, setProgress] = useState(0)
    const [progres2, setProgress2] = useState(0)
    const [progres3, setProgress3] = useState(0)
    const [progres4, setProgress4] = useState(0)
    const navigate = useNavigate()

    const [showpoints, setShowPoints] = useState(true)
    const [flipLogo, setFlipLogo] = useState(false)
    // console.log(language)
    // console.log(aboutData)
    useEffect(() => {
        setLoading(true)
        let newUrl = `${API_STUDENT_PROGRESS}?access_token=${user?.token}`
        axios.post(newUrl).then((response) => {
            if (response.data.message === "Invalid Access Token") {
                authLogout()
                navigate("/");
            } else {
                // console.log(response.data)
                // console.log(response.data.student_summary.course_progress[2].progress_percentage)
                setProgress(Number(response.data.student_summary.course_progress[0].progress_percentage))
                setProgress2(Number(response.data.student_summary.course_progress[1].progress_percentage))
                setProgress3(Number(response.data.student_summary.course_progress[2].progress_percentage))
                setProgress4(Number(response.data.student_summary.course_progress[3].progress_percentage))
                setMyPoints(response.data.student_summary.points)
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        });
    }, [])


    useEffect(() => {

        // Flip logo for 2 seconds
        setShowPoints(true)
        setTimeout(() => {
            setShowPoints(false);
            setFlipLogo(true);

            setTimeout(() => {
                setFlipLogo(false)
            }, 3000)
            // Flip logo again for 2 seconds after showing the points
        }, 6000);
    }, [])


    const customValue = progres;
    const customStyle = {
        '--value': customValue,
    };

    const customValue2 = progres2;
    const customStyle2 = {
        '--value': customValue2,
    };

    const customValue3 = progres3;
    const customStyle3 = {
        '--value': customValue3,
    };

    const customValue4 = progres4;
    const customStyle4 = {
        '--value': customValue4,
    };

    return (
        <>
            <div id="body_bg">
                <Header page_title={language === "english" ? "My Progress" : "मेरी प्रगति"}  />
                <LanguageSet />
                {loading ?
                    (<LoadingSnip />)
                    : (
                        <div className="container-fluid px-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-5 col-md-12">
                                    <section className="my-3">
                                        <div className="container-fluid ">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="sectionsBox">
                                                        <p className="mb-0 text-center">{language === "english" ? "My Progress" : "मेरी प्रगति"}</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row mt-4 justify-content-center">
                                                <div className="col-xl-12 text-center position-relative">
                                                    <div className="progressbarMy mb-5 d-flex gap-4 align-items-center ">
                                                        <div className="section1">
                                                            <p className="mb-0  p_column">
                                                                {language === "english" ? "Section 1" : "अनुभाग 1"}
                                                            </p>
                                                        </div>
                                                        {/*section1 start */}
                                                        {
                                                            progres === 0 ?
                                                                <div className="completeImg stepper-item">
                                                                    <img src={require("../assets/images/lock.png")} alt="lock" />
                                                                </div>
                                                                : (progres > 0 && progres < 80) ?

                                                                    <div className="progress_my" role="progressbar" onClick={() => {
                                                                        navigate("/module/digital-wellbeing", {
                                                                            state: {
                                                                                url: `${Constants.BASE_URL}/course-module-list?id=1`,
                                                                                page_title: { english: "Digital Safety", hindi: "डिजिटल सुरक्षा" }
                                                                            }
                                                                        })
                                                                    }}
                                                                        aria-valuenow={progres > 0 ?
                                                                            progres + "%" :
                                                                            <img src={require("../assets/images/lock.png")} alt="lock" />}
                                                                        aria-valuemin="0" aria-valuemax="100" style={{ ...customStyle, cursor: "pointer" }}>
                                                                    </div>
                                                                    :
                                                                    (progres >= 80) ?
                                                                        (
                                                                            showpoints ?
                                                                                <div className="progress_my" role="progressbar"
                                                                                    aria-valuenow={progres > 0 ?
                                                                                        progres + "%" :
                                                                                        <img src={require("../assets/images/lock.png")} alt="lock" />}
                                                                                    aria-valuemin="0" aria-valuemax="100" style={customStyle}
                                                                                >
                                                                                </div>
                                                                                : <img style={{ width: 120 }} src={require("../assets/images/yellow.png")} className={flipLogo ? "flip-logo" : ""} alt="yellow.png" />
                                                                        )

                                                                        :
                                                                        <div className="completeImg stepper-item">
                                                                            <img src={require("../assets/images/lock.png")} alt="lock" />
                                                                        </div>
                                                        }

                                                    </div>
                                                    <div className="progressbarMy mb-5 d-flex gap-4 align-items-center">
                                                        <div className="section1">
                                                            <p className="mb-0  p_column">
                                                                {language === "english" ? "Section 2" : "अनुभाग 2"}
                                                            </p>
                                                        </div>
                                                        {/*section -2  start */}
                                                        {(progres > 80 && progres2 === 0) ?
                                                            <div onClick={() => {
                                                                navigate("/module/digital-wellbeing", {
                                                                    state: {
                                                                        url: `${Constants.BASE_URL}/course-module-list?id=2`,
                                                                        page_title: { english: "Digital Well-Being", hindi: "डिजिटल देखभाल" }
                                                                    }
                                                                })
                                                            }}
                                                            >
                                                                <img style={{ width: 120, cursor: "pointer" }} src={require("../assets/images/unlock.png")} alt="unlock.png" />
                                                            </div>
                                                            : (progres2 > 0 && progres2 < 80) ?
                                                                <div className="progress_my" role="progressbar" onClick={() => {
                                                                    navigate("/module/digital-wellbeing", {
                                                                        state: {
                                                                            url: `${Constants.BASE_URL}/course-module-list?id=2`,
                                                                            page_title: { english: "Digital Well-Being", hindi: "डिजिटल देखभाल" }
                                                                        }
                                                                    })
                                                                }}
                                                                    aria-valuenow={progres2 > 0 ?
                                                                        progres2 + "%" :
                                                                        <img src={require("../assets/images/lock.png")} alt="unlock.png" />}
                                                                    aria-valuemin="0" aria-valuemax="100" style={{ ...customStyle2, cursor: "pointer" }}>
                                                                </div>
                                                                : (progres2 >= 80) ?
                                                                    (
                                                                        showpoints ?
                                                                            <div className="progress_my" role="progressbar"
                                                                                aria-valuenow={progres2 > 0 ?
                                                                                    progres2 + "%" :
                                                                                    <img src={require("../assets/images/lock.png")} alt="unlock.png" />}
                                                                                aria-valuemin="0" aria-valuemax="100" style={customStyle2}>
                                                                            </div>
                                                                            :

                                                                            <img style={{ width: 120 }} src={require("../assets/images/yellow.png")} className={flipLogo ? "flip-logo" : ""} alt="yellow.png" />

                                                                    )

                                                                    :
                                                                    <div className="completeImg stepper-item">
                                                                        <img src={require("../assets/images/lock.png")} alt="lock.png" />
                                                                    </div>
                                                        }


                                                    </div>
                                                    <div className="progressbarMy mb-5 d-flex gap-4 align-items-center">
                                                        <div className="section1">
                                                            <p className="mb-0  p_column">
                                                                {language === "english" ? "Section 3" : "अनुभाग 3"}
                                                            </p>
                                                        </div>
                                                        {/*section -3  start */}
                                                        {(progres2 > 80 && progres3 === 0) ?
                                                            <div
                                                                onClick={() => {
                                                                    navigate("/module/digital-wellbeing", {
                                                                        state: {
                                                                            url: `${Constants.BASE_URL}/course-module-list?id=3`,
                                                                            page_title: { english: "Digital Information Ecosystem", hindi: "ऑनलाइन मौजूद जानकारी" }
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <img style={{ width: 120, cursor: "pointer" }} src={require("../assets/images/unlock.png")} alt="unlock.png" />
                                                            </div>


                                                            : (progres3 > 0 && progres3 < 80) ?
                                                                <div className="progress_my" role="progressbar"

                                                                    onClick={() => {
                                                                        navigate("/module/digital-wellbeing", {
                                                                            state: {
                                                                                url: `${Constants.BASE_URL}/course-module-list?id=3`,
                                                                                page_title: { english: "Digital Information Ecosystem", hindi: "ऑनलाइन मौजूद जानकारी" }
                                                                            }
                                                                        })
                                                                    }}
                                                                    aria-valuenow={progres3 > 0 ?
                                                                        progres3 + "%" :
                                                                        <img src={require("../assets/images/lock.png")} alt="lock.png" />}
                                                                    aria-valuemin="0" aria-valuemax="100" style={{ ...customStyle3, cursor: "pointer" }}
                                                                >
                                                                </div>
                                                                : (progres3 >= 80) ?
                                                                    (
                                                                        showpoints ?
                                                                            <div className="progress_my" role="progressbar"
                                                                                aria-valuenow={progres3 > 0 ?
                                                                                    progres3 + "%" :
                                                                                    <img src={require("../assets/images/lock.png")} alt="lock.png" />}
                                                                                aria-valuemin="0" aria-valuemax="100" style={customStyle3}>
                                                                            </div>
                                                                            :

                                                                            <img style={{ width: 120 }} src={require("../assets/images/yellow.png")} className={flipLogo ? "flip-logo" : ""} alt="yellow.png" />

                                                                    )
                                                                    :
                                                                    <div className="completeImg stepper-item">
                                                                        <img src={require("../assets/images/lock.png")} alt="lock.png" />
                                                                    </div>
                                                        }


                                                    </div>
                                                    <div className="progressbarMy mb-5 d-flex gap-4 align-items-center">
                                                        <div className="section1">
                                                            <p className="mb-0  p_column">
                                                                {language === "english" ? "Section 4" : "अनुभाग 4"}
                                                            </p>
                                                        </div>
                                                        {/*section - 4  start */}
                                                        {(progres3 > 80 && progres4 === 0) ?
                                                            <div onClick={() => {
                                                                navigate("/module/digital-wellbeing", {
                                                                    state: {
                                                                        url: `${Constants.BASE_URL}/course-module-list?id=4`,
                                                                        page_title: { english: "Digital Agency", hindi: "इंटरनेट का सही इस्तेमाल " }
                                                                    }
                                                                })
                                                            }}>
                                                                <img style={{ width: 120, cursor: "pointer" }} src={require("../assets/images/unlock.png")} alt="unlock.png" />
                                                            </div>
                                                            : (progres4 > 0 && progres4 < 80) ?
                                                                <div className="progress_my" role="progressbar"
                                                                    onClick={() => {
                                                                        navigate("/module/digital-wellbeing", {
                                                                            state: {
                                                                                url: `${Constants.BASE_URL}/course-module-list?id=4`,
                                                                                page_title: { english: "Digital Agency", hindi: "इंटरनेट का सही इस्तेमाल " }
                                                                            }
                                                                        })
                                                                    }}
                                                                    aria-valuenow={progres4 > 0 ?
                                                                        progres4 + "%" :
                                                                        <img src={require("../assets/images/lock.png")} alt="lock.png" />}
                                                                    aria-valuemin="0" aria-valuemax="100" style={{ ...customStyle4, cursor: "pointer" }}>
                                                                </div>
                                                                : (progres4 >= 80) ?
                                                                    (
                                                                        showpoints ?
                                                                            <div className="progress_my" role="progressbar"
                                                                                aria-valuenow={progres4 > 0 ?
                                                                                    progres4 + "%" :
                                                                                    <img src={require("../assets/images/lock.png")} alt="lock.png" />}
                                                                                aria-valuemin="0" aria-valuemax="100" style={customStyle}>
                                                                            </div>
                                                                            :

                                                                            <img style={{ width: 120 }} src={require("../assets/images/yellow.png")} className={flipLogo ? "flip-logo" : ""} alt="yellow.png" />

                                                                    )
                                                                    :
                                                                    <div className="completeImg stepper-item">
                                                                        <img src={require("../assets/images/lock.png")} alt="lock.png" />
                                                                    </div>
                                                        }


                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    )}

            </div>
        </>
    )
}

export default MyProgress