import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import LanguageContext from "../components/hooks/LanguageContext";
import React from "react";


const CommingSoon = ({question}) => {
    const navigate = useNavigate()
    const { language } = React.useContext(LanguageContext);
    const height = window.innerHeight;
    const width = window.innerHeight;
    return (
        <div id="body_bg">

            <Header page_title={"Coming Soon"} />
            <div className='justify-content-end d-flex' >
                <div data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="sectionsBox">
                        {/* <p className="mb-0 text-center">{question.title[language]}</p> */}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xl-7 col-md-12">
                    <div className="bg-light" style={{ height: height, paddingLeft: (width / 4) - 100 }}>
                        <h1 style={{ paddingTop: 200, }}>Coming Soon</h1>
                        <div onClick={() => { navigate('/home') }} className="text-primary" >Go to Home</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommingSoon