import { useContext, useEffect, useState } from "react";
import LanguageContext from "./hooks/LanguageContext";
import parse from 'html-react-parser';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";
import ModalInstruction from "./ModalInstruction";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const PdfShow = ({ question, setPdfMinTime, setPdfCount }) => {

    const { language } = useContext(LanguageContext);
    // const width = window.innerWidth;
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfWidth, setPdfWidth] = useState(100);
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const [windowSize, setWindowSize] = useState([
    //     window.innerWidth,
    //     window.innerHeight,
    // ]);



    useEffect(() => {

        let minTime = Math.round((Number(question.min_duration) * 80) / 100)
        setPdfMinTime(minTime)


    }, [])
    useEffect(() => {
        const interval = setInterval(() => {
            setPdfCount((prevCount) => prevCount + 1);
        }, 1000); // Update counter every 1000 milliseconds (1 second)

        // Clear the interval when the component is unmounted or the effect is re-run
        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleResize = () => {
        const windowWidth = window.innerWidth;

        // Adjust PDF width based on window width
        if (windowWidth <= 768) {
            setPdfWidth(windowWidth - 40); // You can adjust the margin as needed
        } else {
            setPdfWidth(740);
        }
    };

    useEffect(() => {
        // Initial resize
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect(() => {

    //     const handleWindowResize = () => {
    //         setWindowSize([window.innerWidth, window.innerHeight]);
    //     };

    //     window.addEventListener('resize', handleWindowResize);

    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (windowSize[0] <= 280) {
    //         setPdfWidh(265)
    //     } else if (windowSize[0] <= 300) {
    //         setPdfWidh(275)
    //     } else if (windowSize[0] <= 320) {
    //         setPdfWidh(290)
    //     } else if (windowSize[0] <= 375) {
    //         setPdfWidh(350)
    //     } else if (windowSize[0] <= 384) {
    //         setPdfWidh(365)
    //     } else if (windowSize[0] <= 425) {
    //         setPdfWidh(400)
    //     } else if (windowSize[0] <= 768) {
    //         setPdfWidh(740)
    //     } else {
    //         setPdfWidh(740)
    //     }
    // }, [windowSize])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    return (
        <>
            <div className='justify-content-end d-flex' >
                {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                <div onClick={handleShow}>
                    <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' alt="instruvtion" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="sectionsBox">
                        <p className="mb-0 text-center">{question.title[language]}</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-0">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-md-12 my-4 d-flex justify-content-center">

                        {/* {question.title["english"] === "Security vs Privacy " ? */}
                        <div style={{ width: pdfWidth }}>
                            <Document
                                file={question.file_path[language]}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} width={pdfWidth} />
                                <div className="page-controls" style={{ display: "flex", alignItems: "center" }}>
                                    <button style={{ fontSize: 25, fontWeight: 900, color: "#000000" }} type="button" disabled={pageNumber <= 1} onClick={previousPage}>‹</button>
                                    <span style={{ fontWeight: 900, fontSize: 16 }}>Page {pageNumber || (numPages ? 1 : '')} of {numPages || ''}</span>
                                    <button style={{ fontSize: 25, fontWeight: 900, color: "#000000" }} type="button" disabled={pageNumber >= numPages} onClick={nextPage}>›</button>
                                </div>
                            </Document>

                        </div>
                        {/*:
                            <iframe
                                src={question.pdf_link[language]}
                                title="Google Drive PDF Viewer"
                                width="100%"
                                height="600px"
                                frameBorder="0"
                                scrolling="no"
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                            ></iframe>
                        }*/}

                    </div>
                </div>
            </div>

            <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
        </>
    )
}

export default PdfShow