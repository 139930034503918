// src/components/Ball.js
import React from 'react';
import { useDrag } from 'react-dnd';

const Ball = ({ id, isRight, text, ballStatus }) => {
  const [{ isDragging }, drag] = useDrag({
    type: isRight ? 'RIGHT_BALL' : 'WRONG_BALL',
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className='container'
    >

      {ballStatus === false ? <img style={{width:"75px"}} src={require("../../assets/images/dragdrop/gameball.png")} /> :  <img src={require("../../assets/images/dragdrop/gameball.png")} style={{filter:'blur(2px)', width:"75px"}}/>}
      <div className="centered" style={{fontSize:12, color: ballStatus === true ? "#F3DB73" : "#fff"  }}>{text}</div>
    </div>
  );
};

export default Ball;
