import React, { useState } from "react";
import parse from 'html-react-parser';
import "../App.css";

const ReadMoreResourcesShortLongAbout = ({ short, long, filePath, language }) => {
	// const text = data;
	// console.log(data)
	const [isReadMore, setIsReadMore] = useState(false);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	// console.log(isReadMore)
	return (
		<div className="text textP" style={{ margin: 0 }}>
			<img src={filePath} alt="" style={{ width: "90px", marginRight: "15px", float: "left" }} />
			{isReadMore === false ? parse(String(short).replace(/(<p[^>]+?>|<p>|<\/p>)/img, "")) : parse(String(long).replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""))}
			<span onClick={toggleReadMore} className="read-or-hide" style={{ fontWeight: 400, color: "#792110" }}>
				{language === "english" && (isReadMore === false ? " Read more" : " Read less")}
				{language === "hindi" && (isReadMore === false ? " और पढ़ें" : " कम पढ़ें")}
			</span>
		</div>

	)
}


export default ReadMoreResourcesShortLongAbout;
