import { useEffect } from "react";

const ProgressBarPrec = ({progress}) =>{
// console.log(progress)
      // progressBar
  useEffect(() => {
    const bars = document.querySelectorAll(".bar");

    bars.forEach((current) => {
      let startWidth = 0;
      const endWidth = parseInt(current.dataset.size);

      const interval = setInterval(frame, 20);

      function frame() {
        if (startWidth >= endWidth) {
          clearInterval(interval);
        } else {
          startWidth++;
          current.style.width = `${startWidth}%`;
          // console.log(typeof(startWidth))
           // Check if startWidth is a valid number before updating the text content
           if (!isNaN(startWidth)) {
            current.querySelector('.perc').innerText = `${Number(startWidth) <= 15 ? "" : startWidth + "%"}`;
          }
        }
      }
    });

    return () => {
      bars.forEach((current) => {
        current.style.width = '0%';
        current.querySelector('.perc').innerText = '';
      });
    };
  }, [progress]);
  
    return(
        <div className="progress-bar">
        <div className="bar" data-size={Number(progress) || 0}>
          <span className="perc"></span>
        </div>
      </div>
    )
}

export default ProgressBarPrec