import { useContext } from "react"
import LanguageContext from "../hooks/LanguageContext"
import { useLocation } from "react-router-dom"
import { useAuth } from "../../providers/AuthContext"

const LanguageSet = ({ pageTitle }) => {
    const { language, setLanguage } = useContext(LanguageContext)
    const { myPoints } = useAuth()
    const location = useLocation();
    // console.log(location.pathname)

    return (
        <>
            <div className="container-fluid d-flex justify-content-between align-items-center desktop_view_hide" style={{ paddingTop: "4em" }}>
                <div className="hindiTransalte mt-3">
                    <button onClick={() => { setLanguage("hindi") }} style={{ fontWeight: language === "hindi" ? "bolder" : "normal" }}> अ</button> <button onClick={() => { setLanguage("english") }} style={{ fontWeight: language === "english" ? "bolder" : "normal" }}>A</button>
                </div>
                {/* {
                    pageTitle === "AskAge" ? "" :

                        <div className="counter_setCoin">
                            {(location.pathname === "/home" || location.pathname === "/") ? "" :

                                <div className='counter_box mt-3'>
                                    <p className="mb-0">{myPoints}</p>
                                </div>
                            }
                        </div>
                } */}




            </div>
        </>
    )
}


export default LanguageSet;
