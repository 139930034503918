// src/components/Bucket.js
import React from 'react';
import { useDrop } from 'react-dnd';

const BucketMatch = ({ type, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept: type,
    drop: (item) => onDrop(item.quisAnswer),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        width: '60px',
        height: '75px',
        // border: '2px solid black',
        borderRadius: '10px',
        margin: '10px',
        backgroundColor: isOver ? '#eee' : 'white',
      }}
    >
      {children}
    </div>
  );
};

export default BucketMatch;
