import React, { useState } from "react";
import parse from 'html-react-parser';
import "../App.css";

const ReadMoreResourcesShortLong = ({ data, language }) => {
	const text = data;
	const [isReadMore, setIsReadMore] = useState(false);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	
	return (
		<div className="text textP" style={{margin:0}}>
			{isReadMore === false ?  parse(String(data?.short_description[language]).replace(/(<p[^>]+?>|<p>|<\/p>)/img, "")): parse(String(data?.long_description[language]).replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""))}
			<span onClick={toggleReadMore} className="read-or-hide" style={{fontWeight: 400, color:"#792110"}}>
			{language ==="english" && (isReadMore === false ? " Read more" : " Read less")}
				{language === "hindi" && (isReadMore === false ? " और पढ़ें" : " कम पढ़ें")}
			</span>
		</div>
	)
}


export default ReadMoreResourcesShortLong;
