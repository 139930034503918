import Header from "../components/Header"
import LoadingSnipQuiz from "../components/LoadingSnipQuiz"


const NotFoundNoHeader = (error) => {
    // console.log(error.error)
    return (
        <div id="body_bg">

            <Header page_title={"processing"} pageTitle={"AskAge"} />
            <div className="row justify-content-center">
                <div className="col-xl-5 col-md-12">

                    <div className="d-flex align-items-center justify-content-center vh-100 ">
                        {error.error ? (
                            // Render error message
                            <div className="alert alert-danger" role="alert">
                                {error.error}
                            </div>
                        ) : (
                            // Render loading spinner if no error
                            <LoadingSnipQuiz />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundNoHeader