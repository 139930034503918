import { useContext, useEffect, useState } from "react";
import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext";
import { useNavigate } from "react-router-dom";
import digitalAmbassador from "../api/digitalAmbassador.json"
import axios from "axios";
import LoadingSnip from "../components/LoadingSnip";
import ReadMoreResourcesShortLong from "../components/ReadMoreResourcesShortLong";
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";

const DigitalAmbassador = () => {
    const { user, logout: authLogout } = useAuth(); // Use useAuth
    const { language } = useContext(LanguageContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    // console.log(digitalAmbassador)
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        let newUrl = `${Constants.BASE_URL}/appambassador?access_token=${user?.token}`
        // console.log(newUrl)

        axios.post(newUrl).then((response) => {
            if (response.data.message === "Invalid Access Token") {
                authLogout()
                navigate("/");
            } else {
                // console.log(response.data)
                setData(response.data)
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        });
    }, [])

    function reformatDate(dateStr) {
        var dArr = dateStr.split("-");  // ex input: "2010-01-18"
        return dArr[2] + "/" + dArr[1] + "/" + dArr[0].substring(2); //ex output: "18/01/10"
    }
    return (
        <div id="body_bg">
            <Header page_title={digitalAmbassador.page_title[language]}  />
            <LanguageSet />
            {loading ?
                (<LoadingSnip />)
                :
                <div className="container-fluid px-0">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-12">
                            <section className="my-3">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="sectionsBox">
                                                <p className="mb-0 text-center">{data?.app_ambassador_summary?.title[language]}</p>
                                            </div>
                                        </div>

                                        <div className="col-12  mt-4">
                                            <div className="contentbox mb-3 mt-1" style={{ minHeight: "120px" }}>
                                                <p className="float-start me-3">
                                                    <img src={data?.app_ambassador_summary?.image[language]} alt="" style={{ width: "80px", height: "80px", overflow: "contain" }} />
                                                </p>
                                                <div className="content_safty">
                                                    {language === "english" && <ReadMoreResourcesShortLong data={data?.app_ambassador_summary} language={language} />}
                                                    {language === "hindi" && <ReadMoreResourcesShortLong data={data?.app_ambassador_summary} language={language} />}


                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 my-3">
                                            {data?.ambassador_summary.map((item, index) => {

                                                let key;
                                                let contentLink;

                                                switch (item.type) {
                                                    case 'Pdf':
                                                        key = 'pdf';
                                                        contentLink = item[key][language];
                                                        break;
                                                    case 'Video':
                                                        key = 'video';
                                                        contentLink = item[key][language];
                                                        break;
                                                    case 'External Link':
                                                        key = 'external_link';
                                                        contentLink = item[key][language];
                                                        break;
                                                    default:
                                                        // Handle other types if needed
                                                        contentLink = '#';
                                                        break;
                                                }

                                                return (
                                                    <div className="annoucemnet_bx d-flex justify-content-between align-items-center mb-2" key={index}>
                                                        <div className="leftannou">
                                                            <a className="leftannou" href={contentLink} target='_blank' rel='noopener noreferrer'>
                                                                <p className="mb-0">  {item?.title[language]}</p>
                                                            </a>
                                                        </div>
                                                        <div className="rightannou d-flex gap-5 align-items-center">
                                                            <div>
                                                                {/* <p className="mb-0">{reformatDate(item.date)}</p> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })

                                            }
                                            {/* <div className="annoucemnet_bx d-flex justify-content-between align-items-center mb-2">
                                                <div className="leftannou">
                                                    <a className="leftannou" href={"https://drive.google.com/file/d/1nbeThWVubRHYf2l6sQA5YpiA_kOblWbo/view?usp=drive_link"} target='_blank' rel='noopener noreferrer'>
                                                        <p className="mb-0">  {language === "english" ? "Final GC Student instructions" : "अंतिम जीसी छात्र निर्देश"}</p>
                                                    </a>
                                                </div>
                                                <div className="rightannou d-flex gap-5 align-items-center">
                                                    <div>
                                                        <p className="mb-0">26/08/23</p>
                                                    </div>

                                                </div>
                                            </div> */}

                                        </div>


                                    </div>



                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default DigitalAmbassador