// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useState } from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import { Route, Routes } from 'react-router-dom';
import QuestionPage from "./pages/QuestionPage";
import LanguageContext from "./components/hooks/LanguageContext";
import AboutUs from "./pages/AboutUs";
import Announcement from "./pages/Announcement";
import DigitalAgency from "./pages/DigitalAgency";
import DigitalWellBeing from "./pages/DigitalWellBeing";
import DigitalSeafty from "./pages/DigitalSeafty";
import DigitalInfoEcoSystem from "./pages/DigitalInfoEcoSystem";
import MyProgress from "./pages/MyProgress";
import DigitalAmbassador from "./pages/DigitalAmbassador";
import Resources from "./pages/Resources";
import Certificate from "./pages/Certificate";
import NotFound from "./pages/NotFound";
import CommingSoon from "./pages/CommingSoon";
import StudentProfile from "./pages/StudentProfile";
import ShareFeedback from "./pages/ShareFeedback";
import ThankYou from "./pages/ThankYou";
import BaseLineQuiz from "./pages/BaseLineQuiz";
import NotFoundNoHeader from "./pages/NotFoundNoHeader";
import LoadingSnipQuiz from "./components/LoadingSnipQuiz";
import EndLine from "./pages/EndLine";
import { useAuth } from "./providers/AuthContext";




function App() {
  const { user, isLoggedIn, baseLineQuizCompleted, setAgeSubmitted, setBaseLineQuizCompleted, error, loading } = useAuth(); // Use useAuth

  const [language, setLanguage] = useState("english");

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>

        <Routes>
          {isLoggedIn === false ? <Route path="/" element={<LoginPage />} />
            :
            loading ? (
            <Route path="/" element={<LoadingSnipQuiz />} />) :
              <>
                {baseLineQuizCompleted ? (
                  <>
                    <Route path="/" element={<HomePage  />} />
                    <Route path="/home" element={<HomePage  />} />
                    <Route path="/baseLineQuiz" element={<HomePage  />} />
                    <Route path="/module/digital-seafty" element={<DigitalSeafty  />} />
                    <Route path="/module/digital-wellbeing" element={<DigitalWellBeing  />} />
                    <Route path="/module/digital-agency" element={<DigitalAgency  />} />
                    <Route path="/module/digital-infoecosystem" element={<DigitalInfoEcoSystem  />} />
                    <Route path="/module/digital-seafty/items" element={<QuestionPage  />} />
                    <Route path="/module/digital-wellbeing/items" element={<QuestionPage  />} />
                    <Route path="/module/digital-agency/items" element={<QuestionPage  />} />
                    <Route path="/module/digital-infoecosystem/items" element={<QuestionPage  />} />
                    <Route path="/module/items" element={<QuestionPage  />} />
                    <Route path="/endline" element={<EndLine  />} />

                    <Route path="/about-us" element={<AboutUs  />} />
                    <Route path="/annoucement" element={<Announcement  />} />
                    <Route path="/myprogress" element={<MyProgress  />} />
                    <Route path="/digital-ambassador" element={<DigitalAmbassador  />} />
                    <Route path="/resources" element={<Resources  />} />
                    <Route path="/certificate" element={<Certificate  />} />
                    <Route path="/comming-soon" element={<CommingSoon  />} />
                    <Route path="/profile" element={<StudentProfile  />} />
                    <Route path="/share-feedback" element={<ShareFeedback  />} />
                    <Route path="/thank-you" element={<ThankYou  />} />
                    <Route path='*' element={<NotFound />} />
                  </>
                ) : (
                  <>
                    {!baseLineQuizCompleted ? (
                      <>
                        <Route path="/" element={<BaseLineQuiz  setAgeSubmitted={setAgeSubmitted} setBaseLineQuizCompleted={setBaseLineQuizCompleted} />} />
                        <Route path="/baseLineQuiz" element={<BaseLineQuiz  setAgeSubmitted={setAgeSubmitted} setBaseLineQuizCompleted={setBaseLineQuizCompleted} />} />
                      </>

                    ) : null}

                    <Route path='*' element={<NotFoundNoHeader error={error} />} />
                  </>
                )
                }


              </>
          }

        </Routes>
    </LanguageContext.Provider >
  );
}

export default App;
