// src/App.js
import React, { useEffect, useState } from 'react';
import '../App.css';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd';
import BallMatch from '../components/matchQuiz/BallMatch';
import BucketMatch from '../components/matchQuiz/BucketMatch';
import parse from 'html-react-parser';
import ModalInstruction from './ModalInstruction';
import CustomDragLayer from './matchQuiz/CustomDragLayer';

const MatchingQuiz = ({ language, isMarked, question, buckets, setBuckets, answerDragDrop }) => {
  // console.log("question", question)
  // console.log("answerDragDrop", answerDragDrop)
  // console.log(isMarked)
  // console.log(typeof(isMarked))
  // const [ballData, setBallData] = useState([
  //   { id: '1', quisInfo: { english: "Sending a fraudulent message that can be used to obtain someone’s personal information or install harmful software on someone’s computer or phone.", hindi: "जब कोई  हमलावर एक धोखाधड़ी वाला संदेश भेजता है जिससे आपकी निजी जानकारी हासिल की जा सके या आपके कंप्यूटर (computer) या फ़ोन (phone) पर हानिकारक सॉफ़्टवेयर (software) या वायरस (virus) डाला जा सके।" }, quisAnswer: '4', wktStatus: false, ballStatus: false, text: { english: "Malware", hindi: "मालवेयर" } },
  //   { id: '2', quisInfo: { english: "The content whose main purpose is to attract attention and encourage people to click on a specific web page, video, etc.", hindi: "सामग्री या विज्ञापन जिसका मुख्य उद्देश्य ध्यान अपनी ओर खींचना और लोगों  को किसी विशेष वेबसाइट (website) , वीडियो (video) , इत्यादि  के लिंक पर क्लिक करने के लिए प्रोत्साहित करना है।" }, quisAnswer: '3', wktStatus: false, ballStatus: false, text: { english: "Phishing", hindi: "फ़िशिंग" } },
  //   { id: '3', quisInfo: { english: "This involves illegally taking and distributing personal, confidential, or financial information. It is also known as information theft. ", hindi: "किसी की व्यक्तिगत/नीजी , या पैसों या बैंक के खाते से जुड़ी जानकारी को गैरकानूनी रूप से हासिल करना और बांटना ।इसे जानकारी  की चोरी के रूप में भी जाना जाता है।" }, quisAnswer: '2', wktStatus: false, ballStatus: false, text: { english: "Hacking", hindi: "हैकिंग" } },
  //   { id: '4', quisInfo: { english: "Taking and misusing someone’s personal information, such as financial or private information, images, and videos, from someone's computer without their permission.", hindi: "किसी के  कंप्यूटर (computer) में से  व्यक्तिगत जानकारी, जैसे आर्थिक या निजी जानकारी, तसवीरों एवं वीडियो (video)  को चुरा  कर उनका गलत इस्तेमाल करना।" }, quisAnswer: '1', wktStatus: false, ballStatus: false, text: { english: "Identity Theft", hindi: "पहचान की चोरी" } },
  //   { id: '5', quisInfo: { english: "To computer software, such as a virus, that is designed to cause harm to a computer or computer network in ways that the user is unaware of.", hindi: "कंप्यूटर सॉफ़्टवेयर (computer software) जैसे कि एक वायरस जिसके बारे में उपयोग करने वाला नहीं जानता है और जिसे कंप्यूटर या कंप्यूटर नेटवर्क के काम करने के तरीके को नुकसान पहुंचाने के लिए बनाया  गया है।  " },  quisAnswer: '6', wktStatus: false, ballStatus: false, color: 'blue', text: { english: "Data Theft", hindi: "डेटा की चोरी" } },
  //   { id: '6', quisInfo: { english: "Creating a fake identity of someone on the internet and using their personal information for illegal activities.", hindi: "इंटरनेट पर किसी की नकली पहचान बना कर उनकी निजी जानकारी का इस्तेमाल गलत कामो के लिए करना।  " },  quisAnswer: '5', wktStatus: false, ballStatus: false, text: { english: "Clickbait", hindi: "क्लिकबेट" } },

  // ]);
  // console.log(ballData)
  const options = question?.options
  const answer = answerDragDrop?.marked_options
  // console.log(answerDragDrop)
  const [ballData, setBallData] = useState(answer === null ? options : answer);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  // const [buckets, setBuckets] = useState(Array(ballData.length).fill([]));
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])


  // useEffect(() => {
  //   console.log(answer)
  //   if (isMarked === 1) {
  //     setBallData(answer)
  //   }

  // }, [isMarked, answerDragDrop])

  const handleDrop = (ballId, quisAnswer, ballIndex) => {
    // console.log("ballId",ballId)
    // console.log("quisAnswer", quisAnswer)
    // console.log("ballIndex", ballIndex)

    const droppedBall = ballData[ballIndex];
    // const bucketIndex = ballData.findIndex((ball) => ball.quisAnswer === quisAnswer);
    // console.log("droppedBall", droppedBall)
    // console.log("bucketIndex", bucketIndex)
    // Update the corresponding bucket
    // setBuckets((prev) => [...prev, droppedBall]);

    if (!buckets.some((item) => item.id === droppedBall.id)) {
      // If it's not a duplicate, add it to the buckets array
      setBuckets((prevBuckets) => [...prevBuckets, droppedBall]);
    }
    const updatedBalls = ballData.map((ball, index) => {
      // console.log(ball)
      if (ballData[index].id === quisAnswer) {
        return { ...ball, ballStatus: true }
      }
      if (index === ballIndex) {

        return { ...ball, wktStatus: true };
      }
      return ball;
    });
    setBallData(updatedBalls);

  };
  // const touchBackendOptions = {
  //   enableMouseEvents: true,
  //   ignoreContextMenu: true,
  //   delayTouchStart: 200,
  //   delayMouseStart: 200, 
  //   scrollAngleRanges: undefined
  // };




  // console.log(ballData)
  return (
    <DndProvider backend={screenSize.width >= 921 ? HTML5Backend : TouchBackend}>
      <div id="body_bg" >
        <section className="my-3">
          <div className="container-fluid">
            <div className='justify-content-end d-flex' >
              {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
              <div onClick={handleShow}>
                <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="sectionsBox">
                  <p className="mb-0 text-center">{parse(question?.title[language])}</p>
                </div>
              </div>
            </div>

            <div className="row  accotitle">
              <div className="col-12">
                <div className="contentbox mb-2 mt-3">
                  <div className="content_safty ">
                    <p className="mb-0">
                      {parse(question.description[language])}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4" >
                <div style={{ display: "", justifyContent: 'center', alignItems: "center", }}>
                  {ballData?.map((ball, index) => (
                    <div className="questionBox mb-sm-0 mb-md-2" key={index}>
                      <div className="row align-items-center">
                        <div className="col-4 col-sm-3 col-md-3 col-lg-3" >
                          <BallMatch
                            key={ball.id}
                            id={String(ball.id)}
                            quisAnswer={ball.quisAnswer}
                            text={ball.title[language]}
                            ballStatus={ball.ballStatus}
                          />
                        </div>
                        <div className="col-6 col-sm-8 col-md-8  col-lg-8">
                          <div className="content_question position-relative">
                            <p className="mb-0">{parse(ball.info[language])}</p>
                          </div>

                        </div>
                        <div className='col-2 col-sm-1  col-md-1 col-lg-1'>
                          <div style={{ marginLeft: -25 }}>
                            <BucketMatch type={String(ball.quisAnswer)} onDrop={() => handleDrop(ball.id, ball.quisAnswer, index)}>
                              <div className='container bucket-size' style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                {ball?.wktStatus === false
                                  ? <img src={require("../assets/images/dragdrop/match/wt.png")} />
                                  : <img src={require(`../assets/images/dragdrop/match/dcp-gif${index}.gif`)} />
                                }
                              </div>
                            </BucketMatch>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
          </div>
        </section>
      </div>
      {/* <CustomDragLayer /> */}
    </DndProvider>
  );
};

export default MatchingQuiz;
