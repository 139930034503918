import * as React from "react";
import LanguageContext from "./hooks/LanguageContext";
import parse from 'html-react-parser';
import ModalInstruction from "./ModalInstruction";

const ImagePdfLinkComponent = ({ question, handleNextClick, selectedOptions, MarkAsDoneQuiz }) => {
  const { language } = React.useContext(LanguageContext);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(question)
  return (
    <>
      <div className='justify-content-end d-flex' >
        {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
        <div onClick={handleShow}>
          <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="sectionsBox">
            <p className="mb-0 text-center">
              {question.title[language]}
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          {question.description[language].length > 0 && <div className="contentbox mb-2 mt-3">
            <div className="content_safty ">
              {parse(question.description[language])}
            </div>
          </div>
          }
        </div>
      </div>
      <div className="d-flex justify-content-center mx-3">
        <div className="mx-2">
          <img src={question.file_path[language]} useMap={"#image-map"} />
          <map name={"image-map"}>
            <area alt={"first link"} title={"first link"} onClick={() => { MarkAsDoneQuiz("imagemap"); handleNextClick(); }} coords={question.coords[language]} shape={"rect"} />
          </map>


        </div>
      </div>


      <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
    </>
  )
};
export default ImagePdfLinkComponent;
