import { useContext, useEffect, useState } from "react";
import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import LoadingSnipQuiz from "../components/LoadingSnipQuiz";
import { ToastContainer, toast } from 'react-toastify';
import ReactPlayer from "react-player";
import parse from 'html-react-parser';
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";




const SelectAge = ({ selectedAge, setSelectedAge, language }) => {
    const handleAgeChange = (e) => {
        setSelectedAge(e.target.value);
    };

    return (
        <div className="row justify-content-md-center mb-4">
            <div className="col col-lg-2"></div>
            <div className="col-md-auto">
                <select
                    className="form-select"
                    aria-label="Default select example"
                    defaultValue={selectedAge}
                    onChange={handleAgeChange}
                >
                    <option value="" >{language === "english" ? "Select your Age" : "अपनी उम्र चुनें"}</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                </select>
            </div>
            <div className="col col-lg-2"></div>
        </div>
    );
};

const SelectGrade = ({ selectedGrade, setSelectedGrade, language, gradeList }) => {
    const handleAgeChange = (e) => {
        setSelectedGrade(e.target.value);
    };

    return (
        <div className="row justify-content-md-center mb-4">
            <div className="col col-lg-2"></div>
            <div className="col-md-auto">
                <select
                    className="form-select"
                    aria-label="Default select example"
                    defaultValue={selectedGrade}
                    onChange={handleAgeChange}
                    disabled
                >
                    <option value="" >{language === "english" ? "Select your Grade" : "अपनी श्रेणी चुनें"}</option>
                    {gradeList?.map((item, index) => (
                        <option value={item?.id}>{item?.title}</option>
                    ))}
                </select>
            </div>
            <div className="col col-lg-2"></div>
        </div>
    );
};


const SelectGender = ({ selectGender, setSelectedGender, language }) => {
    const handleGenderChange = (e) => {
        setSelectedGender(e.target.value);
    };

    return (
        <div className="row justify-content-md-center mb-4">
            <div className="col col-lg-2"></div>
            <div className="col-md-auto">
                <select
                    className="form-select"
                    aria-label="Default select example"
                    defaultValue={selectGender}
                    onChange={handleGenderChange}
                >
                    <option value="" >{language === "english" ? "Select your Gender" : "अपना लिंग चुनें"}</option>
                    <option value={1}>{language === "english" ? "M" : "पुरुष"}</option>
                    <option value={2}>{language === "english" ? "F" : "महिला"}</option>
                    <option value={4}>{language === "english" ? "Prefer not to say" : "नहीं बताना पसंद करते हैं"}</option>
                    <option value={3}>{language === "english" ? "Other" : "अन्य"}</option>

                </select>
            </div>
            <div className="col col-lg-2"></div>
        </div>
    );
};

const BaseLineQuiz = ({  setBaseLineQuizCompleted, setAgeSubmitted }) => {
    const { user, logout: authLogout } = useAuth(); // Use useAuth

    const [loading, setLoading] = useState(true);
    const [nextPrevBtnShow, setNextPrevBtnShow] = useState(false);
    const [showVideo, setShowVideo] = useState(true)
    const [data, setData] = useState("")
    const { language } = useContext(LanguageContext);
    const navigate = useNavigate()

    const [sectionUrl, setSectionUrl] = useState(null)
    const [question, setQuestion] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [selectedOptions, setSelectedOptions] = useState({});
    const [baseLineId, setBaseLineId] = useState("")
    const [questionId, setQuestionId] = useState("")


    const [, setAnswer] = useState("");
    const [, setIsMarked] = useState(0)
    const [, setFinalSubmit] = useState(false)
    const [optionMarked, setOptionMarked] = useState({})
    const [videoData, setShowVideoData] = useState("")
    const [startQuiz, setStartQuiz] = useState(false)
    const [nextBtnEnable, setNextBtnEnable] = useState(true)


    // State to store the selected age
    const [selectedAge, setSelectedAge] = useState("");
    //Grade List
    const [gradeList, setGradeList] = useState("")
    const [selectedGrade, setSelectedGrade] = useState("");
    // Gender List
    const [selectGender, setSelectedGender] = useState("");

    const [showConsentForm, setShowConsentForm] = useState(user?.is_consent === 0 ? true : false)
    const [consentFormData, setConsentFormData] = useState("");

    const [dataConsent, setDataConsent] = useState(true)

    // Event handler for the "Next" button click
    const handleNextClickAge = async (url) => {
        if (selectedAge !== "" && selectedGrade !== "" && selectGender !== "") {
            // Here, you can use the selectedAge value as needed
            // For example, you can submit it to a server or update your application's state.

            // For now, let's log the selected age to the console
            try {
                setLoading(true)
                const formData = new FormData();
                formData.append("age", selectedAge);
                formData.append("grade_id", selectedGrade);
                formData.append("gender_id", selectGender);
                formData.append("access_token", user?.token);

                // console.log(formData)
                const response = await axios.post(`${Constants.BASE_URL}/student-update-age`, formData,
                    {
                        headers: {
                            'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                            "Access-Control-Allow-Origin": "*"
                        }
                    }
                );
                // console.log(response.data)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    if (response.data.status === 1) {
                        navigate("/baseLineQuiz");
                        let newUrl = `${url}&access_token=${user?.token}`;

                        setSectionUrl(newUrl)
                    } else if (response.data.status === 2) {

                        toast.error(response?.data?.message);

                    }

                }
            } catch (error) {
                console.error(error);
                toast.warn("An error occurred. Please try again.");
            } finally {
                // Set loading to false when the request is complete
                setLoading(false);
            }

            // Navigate to the next page (e.g., BaseLineQuiz)

        } else {
            // Handle the case where no age is selected
            toast.warn("Please select your age, gender and grade.");
        }
    };


    // console.log(user)
    useEffect(() => {
        let url = `${Constants.BASE_URL}/baseline?access_token=${user?.token}`;


        // console.log("start", url)
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(url);
                // console.log(response.data)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response.data)
                    setData(response.data)
                }

                const getAge = `${Constants.BASE_URL}/site/student-age?access_token=${user?.token}`;
                // console.log(getAge)

                const response2 = await axios.get(getAge);
                // console.log(response2.data)
                if (response2.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response2.data)
                    setSelectedAge(response2.data.student_summary.age)
                    setSelectedGrade(Number(response2.data.student_summary.grade_id))
                    setSelectedGender(Number(response2.data.student_summary.gender_id))
                }

            } catch (error) {
                console.error(error);
            } finally {
                // Set loading to false when the request is complete
                setLoading(false);
            }
        }

        fetchData();
    }, [startQuiz])


    useEffect(() => {
        const fetchData = async () => {
            // console.log(sectionUrl)
            let url = `${sectionUrl}&page=${currentPage}`
            // console.log(url)
            try {
                setLoading(true);
                const response = await axios.get(url);
                // console.log(response.data)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response.data)
                    setNextPrevBtnShow(true)
                    setQuestion(response.data)
                    setTotalCount(response?.data?.summary?.total_page)
                    setCurrentPage(response?.data?.summary?.current_page)
                    setBaseLineId(response?.data?.baseline_question[0]?.baseline_id)
                    setQuestionId(response?.data?.baseline_question[0]?.id)
                    // setHtmlData(HTMLReactParser(response?.data?.baseline_summary?.description[language]));
                }

                let answerUrl = `${Constants.BASE_URL}/baseline-response?access_token=${user?.token}&page=${currentPage}&baseline_id=${baseLineId}`;
                // console.log(answerUrl)
                const response2 = await axios.get(answerUrl);
                if (response2.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response2.data)
                    setIsMarked(response2.data.baseline_question[0].is_marked)
                    setOptionMarked(response2.data.baseline_question[0].option_marked)
                    setFinalSubmit(response2.data.baseline_question[0].is_final_submit)
                    setSelectedOptions(response2.data.baseline_question[0].option_marked)
                    if (response2.data.baseline_question[0].is_marked === 1) {
                        setNextBtnEnable(true)
                    }
                }

            } catch (error) {
                console.error(error);
            } finally {
                // Set loading to false when the request is complete
                setLoading(false);
            }
        }
        if (sectionUrl !== null) {
            fetchData();
        }

    }, [sectionUrl, currentPage])

    const startSection1 = (url) => {
        handleNextClickAge(url);
        // if (selectedAge !== "" ) {
        //     let newUrl = `${url}&access_token=${user?.token}`;
        //     // console.log(newUrl)
        //     setSectionUrl(newUrl)
        // } else {
        //     toast.warn("An error occurred. Please try again.");
        // }
    }
    const handleOptionChange = (option, index) => {
        // console.log(option)
        setSelectedOptions(option);
        setNextBtnEnable(true)
    };

    const handleNextClick = async () => {
        setNextBtnEnable(false)
        if (selectedOptions.id) {
            if (Number(currentPage) < Number(totalCount)) {
                // Assuming you have an API endpoint for submitting answers
                const submitUrl = `${Constants.BASE_URL}/baseline-markasdone?baseline_id=${baseLineId}&baseline_question_id=${questionId}&option_id=${selectedOptions.id}&access_token=${user?.token}`;
                // console.log(submitUrl)
                // console.log(selectedOptions)
                // console.log(typeof (selectedOptions.id))
                if (Object.keys(selectedOptions).length > 0) {

                    try {
                        setLoading(true);

                        const response = await axios.post(submitUrl);
                        // console.log("Answer Response JSON:", response.data);
                        if (response.data.message === "Invalid Access Token") {
                            authLogout()
                            navigate("/");
                        } else {
                            setAnswer(response.data)
                        }
                        setCurrentPage(Number(currentPage) + 1);
                        setSelectedOptions({})

                    } catch (error) {
                        console.error(error);
                        setSelectedOptions({})

                    } finally {
                        // Set loading to false when the request is complete
                        setLoading(false);
                    }
                } else {
                    // setCurrentPage(Number(currentPage) + 1)
                    // setSelectedOptions({})
                    toast.warn("Please choose an option.");
                }



            } else if (Number(currentPage) === Number(totalCount)) {
                // setBaseLineQuizCompleted(true); setAgeSubmitted(true)
                // navigate("/")
                //    setCurrentPage(1)
                setFinalSubmit(true)
            } else {
                // console.log("next")
                setCurrentPage(1)
            }

        } else {
            toast.error("Please select and option");
        }

    }

    const handlePreviousClick = () => {
        // if(Number(currentPage) === 1){
        //     console.log("check")
        //     setSectionUrl("")             //remove this line
        //     setNextPrevBtnShow(false)     // remove this line
        // }else
        if (Number(currentPage) !== 1) {
            setCurrentPage(Number(currentPage) - 1)
        } else if (Number(currentPage) === 1) {
            setCurrentPage(1)
            navigate("/")
        } else {
            setCurrentPage(1)
        }
    }

    useEffect(() => {
        let url = `${Constants.BASE_URL}/baseline-video?access_token=${user?.token}`;


        // console.log("start", url)
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(url);
                // console.log(response.data)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response.data)
                    setShowVideoData(response.data)
                }
            } catch (error) {
                console.error(error);
            } finally {
                // Set loading to false when the request is complete
                setLoading(false);
            }
        }

        fetchData();
    }, [showVideo, user?.token, navigate])


    useEffect(() => {
        let url = `${Constants.BASE_URL}/student-grade-list?access_token=${user?.token}`;
        let url2 = `${Constants.BASE_URL}/consent-detail?access_token=${user?.token}`;

        const fetchData = async () => {
            try {
                setLoading(true);
                // const response = await axios.get(url);
                // // console.log(response.data)
                const [response, concentDetail] = await Promise.all([
                    axios.get(url),
                    axios.get(url2)
                ])
                // console.log(concentDetail)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response.data)
                    // setShowVideoData(response.data)
                    setGradeList(response.data.class_summary)
                }

                if (concentDetail.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    setConsentFormData(concentDetail?.data?.consent_summary)
                }

            } catch (error) {
                console.error(error);
            } finally {
                // Set loading to false when the request is complete
                setLoading(false);
            }
        }

        fetchData();
    }, [])

    const renderQuestion = (data) => {
        // console.log(data)
        return (
            <>

                <div className="textBox pb-1 pt-2 position-relative">
                    <div className="shareEmail3 ">

                        <div><p>{data.title[language]}</p></div>
                        <form className="single-type pt-2">
                            <div className="row justify-content-center text-center">
                                {data?.options?.map((option, index) => (
                                    <label key={index}>
                                        <input
                                            type="radio"
                                            name="option"
                                            value={option}
                                            checked={Number(optionMarked.id) === Number(option.id) ? Number(optionMarked.id) === Number(option.id) : Number(selectedOptions.id) === Number(option.id)}
                                            onChange={() => handleOptionChange(option, index)}
                                        />
                                        <span style={{ textAlign: "start" }}>{option.title[language]} </span>

                                    </label>
                                ))
                                }
                            </div>
                        </form>
                    </div>
                </div>

            </>
        )
    }

    const finalSubmitReq = async () => {
        // console.log("clicked")


        try {
            setLoading(true);
            if (Object.keys(selectedOptions).length > 0) {
                const submitUrl = `${Constants.BASE_URL}/baseline-markasdone?baseline_id=${baseLineId}&baseline_question_id=${questionId}&option_id=${selectedOptions.id}&access_token=${user?.token}`;
                const response = await axios.post(submitUrl);
                // console.log("Answer Response JSON:", response.data);
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    setSelectedOptions({})
                }
            }

            // Call the second API using data from the first API
            const response2 = await fetch(`${Constants.BASE_URL}/baseline-final-submit?access_token=${user?.token}&baseline_id=${baseLineId}`);
            const data2 = await response2.json();
            // console.log(data2)
            if (data2?.summary?.final_submit_status === 1) {
                setBaseLineQuizCompleted(true); setAgeSubmitted(true)
                navigate("/")
            } else {
                toast.error(data2?.summary?.message);
                // setBaseLineQuizCompleted(true); setAgeSubmitted(true)
                // navigate("/")
            }
        } catch (error) {
            console.error(error);
            setSelectedOptions({})
        } finally {
            // Set loading to false when the request is complete
            setLoading(false);
        }

    }

    const submitConsentForm = async () => {
        // console.log("true",dataConsent)
        if (dataConsent === false) {
            toast.error("Please select terms and condition.");
            return
        } else if (dataConsent === true) {

            try {
                setLoading(true)
                const url = `${Constants.BASE_URL}/student-consent`;
                const formData = new FormData();
                formData.append("access_token", user?.token);
                formData.append("consent", 1);
                const response = await axios.post(
                    url,
                    formData,
                    {
                        headers: {
                            'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                            "Access-Control-Allow-Origin": "*"
                        },
                    }
                );

                //   console.log(response)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log("response.data.result.is_consent",response.data.result.is_consent)
                    setShowConsentForm(false)
                    let userData = user
                    userData.is_consent = 1  // change the value of is_consent
                    localStorage.setItem("user", JSON.stringify(userData));  // update user in local storage
                }
            } catch (error) {
                console.error(error);
                toast.warn("An error occurred. Please try again.");
            } finally {
                // Set loading to false when the request is complete
                setLoading(false);
            }
        }


    }
    return (
        <>
            <div id="body_bg">
               {user && <Header
                    page_title={(user?.student_name[language] || '' === null ? "" : user?.student_name[language]) + " (" + user?.student_id + ")"}

                pageTitle={"AskAge"}
                />}

                <LanguageSet pageTitle={"AskAge"} />
                {loading ? (<LoadingSnipQuiz />)
                    : (
                        showConsentForm ?

                            <div className="container-fluid px-0">
                                <div className="row justify-content-center">
                                    <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                                        {consentFormData && <section className="my-3">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="sectionsBox">
                                                            <p className="mb-0 text-center">{language === "english" ? "Student Consent" : "छात्र की सहमति"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-0 text-center p-1 mt-2 mb-2">{parse(consentFormData?.description[language])}</div>
                                                            <div className="form-check">
                                                                <input className="form-check-input me-2" type="checkbox"
                                                                    required
                                                                    defaultChecked={dataConsent}
                                                                    onChange={e => setDataConsent(!(dataConsent))}
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {consentFormData?.short_condition[language]}
                                                                </label>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button onClick={() => { submitConsentForm() }} type="button" className="btn btn-danger  custom-button-next">
                                                            {language === "english" ? "Submit" : "जमा करें"}
                                                        </button>
                                                    </div>
                                                </form>



                                            </div>
                                        </section>
                                        }
                                    </div>
                                </div>

                            </div>
                            :

                            (!showVideo ?
                                <>
                                    {!nextPrevBtnShow ?
                                        (<div className="container text-center">
                                            <div className="row justify-content-md-center">
                                                <div className="col col-lg-2">

                                                </div>
                                                <div className="col-md-auto">
                                                    <div className="sectionsBox">
                                                        <p className="mb-0 text-center">{data?.baseline_summary?.title[language]}</p>
                                                    </div>
                                                    <div className="contentbox mb-4 mt-3">
                                                        {data?.baseline_summary?.description[language].length > 0 && <div className="content_safty ">
                                                            {parse(data?.baseline_summary?.description[language])}
                                                        </div>
                                                        }
                                                        {data?.baseline_summary?.instruction[language].length > 0 && <div className="content_safty_ol_li ">
                                                            {parse(data?.baseline_summary?.instruction[language])}

                                                        </div>
                                                        }

                                                    </div>



                                                </div>
                                                <div className="col col-lg-2">

                                                </div>
                                            </div>
                                            <SelectAge selectedAge={selectedAge} setSelectedAge={setSelectedAge} language={language} />
                                            <SelectGrade gradeList={gradeList} setSelectedGrade={setSelectedGrade} selectedGrade={selectedGrade} language={language} />
                                            <SelectGender selectGender={selectGender} setSelectedGender={setSelectedGender} language={language} />
                                            <div className="row justify-content-md-center">
                                                <div className="col col-lg-2">

                                                </div>
                                                <div className="col-md-auto">
                                                    <button onClick={() => { startSection1(data?.baseline_summary?.url) }} type="button" className="btn btn-danger  custom-button-next">
                                                        {language === "english" ? "Next" : "अगला"}
                                                    </button>
                                                </div>
                                                <div className="col col-lg-2">

                                                </div>
                                            </div>
                                        </div>
                                        )

                                        :
                                        (<div className="container-fluid px-0 background-image">
                                            <div className="row justify-content-center">
                                                <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                                                    <section className="my-3">
                                                        <div className="container-fluid">
                                                            <>
                                                                {renderQuestion(question.baseline_question[0])}

                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="mark-buttons d-flex justify-content-between " style={{ marginTop: 150, marginBottom: 100 }}>
                                                                            <div className="left-btn"> <button onClick={handlePreviousClick}
                                                                                disabled={Number(currentPage) === 1}
                                                                                className="previous_btn_baseline"
                                                                            >
                                                                                <FontAwesomeIcon icon={faArrowLeft} size="lg" color="#fff" />
                                                                            </button>

                                                                            </div>

                                                                            {Number(currentPage) === Number(totalCount) ? <div className="markButtons"> <button
                                                                                className="d-flex align-items-center gap-2"
                                                                                onClick={() => { finalSubmitReq() }}
                                                                                style={{ backgroundColor: nextBtnEnable === false ? "#c8c8c8" : Object.keys(selectedOptions).length === 0 ? "#c8c8c8" : "#792110", color: nextBtnEnable === false ? "#212529" : Object.keys(selectedOptions).length === 0 ? "#212529" : "#ffffff" }}
                                                                                disabled={nextBtnEnable === false ? true : Object.keys(selectedOptions).length === 0 ? true : false}
                                                                            >
                                                                                {language === "english" ? "Submit" : "जमा करें"}
                                                                                {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}

                                                                            </button>
                                                                            </div>
                                                                                : ""}
                                                                            <div className="right-btn">
                                                                                <button className="previous_btn_baseline"
                                                                                    onClick={handleNextClick}
                                                                                    disabled={Number(currentPage) === Number(totalCount) ? Number(currentPage) === Number(totalCount) : nextBtnEnable === false ? true : Object.keys(selectedOptions).length === 0 ? true : false}
                                                                                    style={{ backgroundColor: Number(currentPage) === Number(totalCount) ? "#c8c8c8" : nextBtnEnable === false ? "#c8c8c8" : Object.keys(selectedOptions).length === 0 ? "#c8c8c8" : "#792110" }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faArrowRight} size="lg" color="#fff" />
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    }
                                </>
                                :
                                <div className="container-fluid px-0">
                                    <div className="row justify-content-center">
                                        <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-12">
                                            <section className="my-3">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="sectionsBox">
                                                                <p className="mb-0 text-center">{videoData?.baseline_video_summary?.title[language]}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {/* <p>{`${videoData?.baseline_video_summary?.video_link[language]}-U`}</p> */}
                                                            <div className="my-3 d-flex justify-content-center">

                                                                <ReactPlayer
                                                                    url={`${videoData?.baseline_video_summary?.video_link[language]}-U`}
                                                                    playing={true}
                                                                    width="640px"
                                                                    height='360px'
                                                                    controls={true}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 ">
                                                        {/* {videoData?.baseline_video_summary?.description[language].length > 0 && <div className="contentbox mb-4">
                                                        <div className="content_safty ">
                                                            {parse(videoData?.baseline_video_summary?.description[language])}
                                                        </div>
                                                    </div>
                                                    } */}
                                                    </div>


                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button onClick={() => { setStartQuiz(true); setShowVideo(false) }} type="button" className="btn btn-danger  custom-button-next">
                                                            {language === "english" ? "Next" : "अगला"}
                                                        </button>
                                                    </div>


                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </div>
                            ))
                }
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div >
        </>
    )
}

export default BaseLineQuiz