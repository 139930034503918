// src/components/Bucket.js
import React from 'react';
import { useDrop } from 'react-dnd';

const Bucket = ({ type, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept: type,
    drop: (item) => onDrop(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        width: '80px',
        height: '130px',
        // border: '2px solid black',
        borderRadius: '10px',
        margin: '18px',
        backgroundColor: isOver ? '#eee' : 'white',
      }}
    >
      {children}
    </div>
  );
};

export default Bucket;
