import React, { forwardRef, useImperativeHandle, useState } from "react";

import "./BitcoinAnimation.css";
import Coin from "../coin/Coin";



const BitcoinAnimation15 = forwardRef((props, ref) => {
  const [score, setScore] = useState(0);
  const [coins, setCoins] = useState([]);

  useImperativeHandle(ref, () => ({
    addCoin15() {
      // Generate a unique key for each coin using the current timestamp
      const coinKey = new Date().getTime().toString();
      setCoins((prevCoins) => [...prevCoins, coinKey]);
      setScore((prevScore) => prevScore + 1);

    }
  }));


  return (
    <div className="coin-game-container">
      {/* <button className="add-coin-button" onClick={addCoin}>
        Add Coin
      </button>
      <div className="score">Score: {score}</div> */}
      <div className="coins-container">
        {coins.map((coin) => (
          <div key={coin} className="coin-animation" onAnimationEnd={() => setCoins((prevCoins) => prevCoins.filter((c) => c !== coin))}>
            {/* <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.8668 17.7295C23.3501 19.7961 20.1335 18.7461 19.1335 18.4628L20.0501 14.8295C21.0835 15.1295 24.4001 15.5628 23.8668 17.7295ZM18.6835 20.3295L17.6835 24.3461C18.9168 24.6628 22.7335 25.8795 23.3001 23.6128C23.9001 21.2461 19.9168 20.6295 18.6835 20.3295ZM36.3001 24.1628C34.0668 33.0961 25.0335 38.5295 16.1001 36.2961C7.1668 34.0628 1.73847 25.0295 3.9668 16.0961C4.4962 13.9729 5.43869 11.9748 6.74042 10.216C8.04214 8.45707 9.67762 6.97185 11.5534 5.84513C13.4292 4.7184 15.5086 3.97224 17.6729 3.64927C19.8371 3.32629 22.0438 3.43283 24.1668 3.9628C33.0835 6.19613 38.5168 15.2295 36.3001 24.1628ZM23.8168 13.5461L24.5668 10.5461L22.7335 10.1295L22.0001 13.0128C21.5168 12.8961 21.0335 12.7795 20.5335 12.6795L21.2668 9.72946L19.4501 9.29613L18.7001 12.2795C18.3001 12.1795 17.9001 12.0961 17.5335 11.9961L15.0168 11.3628L14.5168 13.3128C14.5168 13.3128 15.8835 13.6295 15.8501 13.6461C16.6001 13.8295 16.7335 14.2961 16.7001 14.7128L14.6501 22.9295C14.5668 23.1628 14.3001 23.4628 13.8168 23.3795C13.8335 23.3961 12.4835 23.0461 12.4835 23.0461L11.5835 25.1295L13.9501 25.7295C14.4001 25.8461 14.8335 25.9628 15.2668 26.0628L14.5001 29.0961L16.3335 29.5628L17.0835 26.5461C17.5835 26.6795 18.0668 26.7961 18.5335 26.9295L17.7835 29.9128L19.6168 30.3795L20.3835 27.3461C23.4668 27.9295 25.8335 27.6961 26.8001 24.8795C27.6335 22.6295 26.8001 21.2961 25.1335 20.4461C26.3335 20.1295 27.2335 19.3795 27.4835 17.7461C27.8168 15.5295 26.1168 14.3461 23.8168 13.5461Z" fill="#F5BC00" />
              <circle cx="19.8667" cy="20.1333" r="12.1333" fill="#F5BC00" />
              <path d="M9.344 25.216H10.592C11.248 25.216 11.576 24.896 11.576 24.256V16.744L9.968 17.728L8.36 15.088L12.512 12.472H15.992V24.256C15.992 24.896 16.32 25.216 16.976 25.216H18.224V28H9.344V25.216ZM25.4338 28.36C22.8898 28.36 20.8098 27.672 19.1938 26.296V23.44H23.6098V25.144C24.2178 25.416 24.8578 25.552 25.5298 25.552C26.1858 25.552 26.6578 25.312 26.9458 24.832C27.2498 24.336 27.4018 23.6 27.4018 22.624C27.4018 21.744 27.1938 21.104 26.7778 20.704C26.3618 20.288 25.7218 20.08 24.8578 20.08H19.6978V18.256L20.1777 12.472H31.3138V15.28H24.3298L24.1138 17.248H25.3618C29.6498 17.248 31.7938 19.04 31.7938 22.624C31.7938 24.464 31.2658 25.88 30.2098 26.872C29.1698 27.864 27.5778 28.36 25.4338 28.36Z" fill="white" />
            </svg> */}
            <img src={require("../../assets/images/coins/15.png")} alt="15 coin"/>

          </div>
        ))}
      </div>
    </div>
  );
});

export default BitcoinAnimation15;
