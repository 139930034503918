import React, { useContext, useEffect, useState } from "react";
const { default: LanguageContext } = require("./hooks/LanguageContext");

const SatyaRakshakGame = ({question, language}) => {
console.log("question", question)
  const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);

  // Update device height on resize
  useEffect(() => {
    const handleResize = () => setDeviceHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="justify-content-end d-flex">
        {/* <div>
          <img
            src={require("../assets/images/instructions.png")}
            className="mobielViewIcon"
          />
        </div> */}
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="sectionsBox text-center">
           
            <p className="mb-0 text-center" style={{ marginLeft: 10 }}>
              { question?.title[language]}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <iframe
          src="https://www.satyarakshak.com/"
          name="iframe_a"
          width="100%"
          height={`${deviceHeight - 250}px`} // Adjust as needed
          title="Iframe Example"
        ></iframe>
      </div>
    </>
  );
};

export default SatyaRakshakGame;
