import { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import LanguageContext from "../components/hooks/LanguageContext";
import LanguageSet from "../components/language/LanguageSet";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import LoadingSnip from "../components/LoadingSnip";
import Constants from "../common/Constants";
import { useAuth } from "../providers/AuthContext";
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const ShareFeedback = () => {
    const { user, logout: authLogout, setMyPoints} = useAuth(); // Use useAuth
    // console.log(quues)
    const navigate = useNavigate()
    const { language } = useContext(LanguageContext);
    const [selected, setSelected] = useState(null);
    const [feedback, setFeedback] = useState("");
    const [pageData, setPageData] = useState("");
    const [loading, setLoading] = useState(false);

    const [feedbackHistory, setFeedbackHistory] = useState([])

    // console.log(user)
    const url = `${Constants.BASE_URL}/feedback`;
    const QUERY_TYPES = [
        { value: 1, label: "Questions about the program" },
        { value: 2, label: "Feedback about the program" }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const formData = new FormData();
                formData.append("program_type", selected);
                formData.append("feedback", feedback);
                formData.append("access_token", user?.token);

                const response = await axios.post(url, formData,
                    {
                        headers: {
                            'x-api-key': 'fc9f4a47-4f7f-41ba-a656-d30e0009d6f0',
                            "Access-Control-Allow-Origin": "*"
                        }
                    }
                );
                handleApiResponse(response.data);
            } catch (error) {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            }
        }
    };

    const handleApiResponse = (data) => {
        if (data.message === "Feedback Submitted Successful") {
            toast.success('Thank You, Feedback submited');
            setSelected(null)
            setFeedback("")
        } else {
            toast.warning(data.message);
        }
    };

    const validate = () => {
        if (!selected) {
            toast.warning('Please select an option.');
            return false;
        }
        if (!feedback) {
            toast.warning('Please provide your feedback.');
            return false;
        }
        return true;
    };

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            let newUrl = `${Constants.BASE_URL}/appfeedback?access_token=${user?.token}`;
            // console.log(newUrl)
            try {
                const response = await axios.get(newUrl);
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    setPageData(response?.data?.feedback_summary)
                }

                const response2 = await axios.get(`${Constants.BASE_URL}/teacherfeedback?access_token=${user?.token}`);
                if (response2.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    console.log(response2)
                    setFeedbackHistory(response2?.data?.feedback_response)
                    setLoading(false)
                }

                const response3 = await axios.post(`${API_STUDENT_PROGRESS}?access_token=${user?.token}`);
                // console.log("Answer Response JSON:", response3.data);
                if (response3.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {

                    setMyPoints(response3.data.student_summary.points)
                    setLoading(false);
                }
            }
            catch (error) {
                console.error(error);
                setLoading(false)
            }
        }
        fetchData();
    }, [])
    // console.log(feedbackHistory)
    return (
        <>
            <Header page_title={language === "english" ? "Feedback" : "प्रतिक्रिया"}  />
            <LanguageSet />
            {loading ?
                (<LoadingSnip />)
                : (
                    <div className="container-fluid px-0">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-md-12">
                                <section className="my-3">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="sectionsBox">
                                                    <p className="mb-0 text-center">
                                                        {pageData && pageData.title[language]}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 border rounded-1 my-4">
                                                <div style={{ backgroundColor: "rgba(243, 211, 208, 0.40)" }} className="feedback-dicription border-bottom px-2 py-3">
                                                    <p className="mb-0"> {pageData && pageData.description[language]}</p>

                                                </div>
                                                <div className="my-2 p-2">
                                                    <p>{pageData && pageData.program_text[language]}</p>
                                                </div>
                                                <div>
                                                    <form className="single-type" onSubmit={handleSubmit}>

                                                        <label >
                                                            <input
                                                                type="radio"
                                                                name="option"
                                                                value={1}
                                                                checked={selected === 1}
                                                                onChange={() => setSelected(1)}
                                                            />
                                                            <span>
                                                            {pageData && pageData.program_type[language][0]}
                                                            </span>
                                                        </label>

                                                        <label >
                                                            <input
                                                                type="radio"
                                                                name="option"
                                                                value={2}
                                                                checked={selected === 2}
                                                                onChange={() => { setSelected(2) }}
                                                            />
                                                            <span>
                                                            {pageData && pageData.program_type[language][1]}

                                                            </span>
                                                        </label>
                                                        <div className="p-2">
                                                            <textarea
                                                                className="form-control"
                                                                placeholder={language === "english" ? "Add your comments here:" : "अपने प्रश्न सुझाव यहाँ लिखें:"}
                                                                id="floatingTextarea"
                                                                value={feedback}
                                                                onChange={(event) => { setFeedback(event.target.value) }}
                                                            ></textarea>
                                                        </div>
                                                        <div className="feedbackButtons d-flex justify-content-center mb-5 mt-2">
                                                            <button className="d-flex align-items-center gap-2" type="submit"  >
                                                                {language === "english" ? "Submit!" : "जमा करें"}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 shareFeedBack border rounded-1">
                                                <div className="sectionsBox mt-2 m-1">
                                                    <p className="mb-0 text-center">
                                                        {language === "english" ? "Responses to your queries" : "आपके प्रश्नों के उत्तर"}
                                                    </p>
                                                </div>
                                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                                    {feedbackHistory?.map((item, index) => (
                                                        <div className="accordion-item" key={index}>
                                                            <h2 className="accordion-header" style={{overflowWrap:"anywhere"}} >
                                                                <button style={{ padding: 10, color: "#212529" }} className="accordion-button-feedback collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                                                    <FontAwesomeIcon className="minus iconPM" icon={faMinus} size="xl" color="#212529" />
                                                                    <FontAwesomeIcon className="plus iconPM" icon={faPlus} size="xl" color="#212529" />
                                                                    {item?.feedback}
                                                                </button>
                                                            </h2>
                                                            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                                <div className="accordion-body" style={{overflowWrap:"anywhere"}}>{item?.response}</div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div>
                                            </div> */}
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={5000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="light"
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default ShareFeedback;
