import { Modal } from "react-bootstrap"

const ModalInstruction = ({ show, handleClose, inst }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>{inst}</Modal.Body>
        </Modal>
    )
}

export default ModalInstruction