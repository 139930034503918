// AuthContext.js
import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
import Constants from '../common/Constants';
const API_STUDENT_PROGRESS = `${Constants.BASE_URL}/studentprogress`;

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
   /* global gtag */
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("")
  const [ageSubmitted, setAgeSubmitted] = useState(false);
  const [baseLineQuizCompleted, setBaseLineQuizCompleted] = useState(false);
  const [error, setError] = useState("")
  const [myPoints, setMyPoints] = useState(0);
  const [loading, setLoading] = useState(true)

  // Check if the user is logged in on component mount (e.g., using localStorage)
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      setIsLoggedIn(true);
      handleLogin(storedUser.token)
    } else {
      setIsLoading(false); // Update loading state
    }
  }, []);


  const handleLogin = (token) => {
    // Your useEffect logic goes here
    const fetchData = async () => {
      try {
        setLoading(false)
        // console.log(temp.token)
        const response2 = await axios.post(`${API_STUDENT_PROGRESS}?access_token=${token}`);
        // console.log("Answer Response JSON:", response2.data);
        if (response2.data.message === "Invalid Access Token") {
          // authLogout()

        } else {
          setMyPoints(response2.data.student_summary.points)
        }

        const statusUrl = `${Constants.BASE_URL}/site/student-details-status?access_token=${token}&baseline_id=1`;
        const response3 = await axios.post(statusUrl);
        // console.log(response3.data)
        if (response3.data.message === "Invalid Access Token") {
          // authLogout()
          // navigate("/");

        } else {
          // console.log("response3?.student_summary?.final_submit_status", response3?.data?.student_summary?.final_submit_status)
          if (response3?.data?.student_summary?.final_submit_status === 1) {
            setAgeSubmitted(true);
            setBaseLineQuizCompleted(true)
            setLoading(false)
          } else {
            setAgeSubmitted(false); setBaseLineQuizCompleted(false)
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error);
        setError(error?.message)
      }
    }
    fetchData();
  };
  

  const login = (userData) => {
   console.log(userData)
    setIsLoggedIn(true);
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    handleLogin(userData.token)
    gtag('config', 'G-4YJV39FYNP', {
        'dimension1': userData.id
      }
    );
  };

  const logout = () => {
    // Perform the logout logic (clear tokens, user info, etc.)
    setIsLoggedIn(false);
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, isLoading, ageSubmitted, baseLineQuizCompleted, myPoints, loading, setBaseLineQuizCompleted, error, setMyPoints }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
