import { useContext, useEffect, useState } from "react";
import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"
import LanguageContext from "../components/hooks/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import digitalSeafty from "../api/digitalSeafty.json"
import axios from "axios";
import parse from 'html-react-parser';
import ProgressBarPrec from "../components/ProgressBarPrec";
import Modal from 'react-bootstrap/Modal';
import QuizGuid from "../components/QuizGuide";
import { useAuth } from "../providers/AuthContext";


const DigitalWellBeing = () => {
    const { user, logout: authLogout, setMyPoints} = useAuth(); // Use useAuth
    const height = window.innerHeight;
    // console.log(height)
    const [loading, setLoading] = useState(false);
    const [module, setModule] = useState(null)
    const { language } = useContext(LanguageContext);
    const [progres, setProgress] = useState(0)
    const [sectionStatus, setSectionStatus] = useState(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate()
    const { state } = useLocation();
    // const { url, page_title } = state; // Read values passed on state
    // console.log(url, page_title)
    // console.log(user)
    let url, page_title;

    if (state && state.url !== undefined && state.page_title !== undefined) {
        url = state.url;
        page_title = state.page_title;
    } else {
        // Handle the case where state or its properties are undefined
        // You can assign default values or show an error message here
        navigate("/home")
    }



    useEffect(() => {
        setLoading(true)
        // console.log(url)
        let newUrl = `${url}&access_token=${user?.token}`
        // console.log(newUrl)
        const fetchData = async () => {
            try {
                const response = await axios.post(newUrl);
                // console.log(response)
                if (response.data.message === "Invalid Access Token") {
                    authLogout()
                    navigate("/");
                } else {
                    // console.log(response.data)
                    setModule(response.data)
                    setSectionStatus(response.data.course_summary.previous_course_status)
                    setProgress(response.data.course_summary.progress)
                    setMyPoints(response.data.course_summary.points)
                    setLoading(false);
                }
            }
            catch (error) {
                console.error(error);
                setLoading(false)
            }


        }
        fetchData();
    }, [])


    const calculateMarking = (totalItem, markedItem, no_modules) => {
        let marking = false;
        if (totalItem > 0 && totalItem === markedItem) {
            marking = true
        } else if (totalItem > markedItem) {
            let percentage = Math.round((markedItem * 100) / totalItem)
            if (percentage >= 80) {
                marking = true
            }
        } else if (totalItem < markedItem) {

            marking = true

        }
        else {
            marking = false
        }

        return marking
    }
    const SetNavigate = (item, index) => {
        // console.log(item)
        switch (item.title[language]) {
            // case "2 Factor Authentication ":
            //     return navigate("/itemsImagePdf")
            //     break;
            // case "टू-फैक्टर ऑथेंटिकेशन":
            //     return navigate("/itemsImagePdf")
            //     break;
            // case "Understanding privacy setting":
            //     return navigate("/itemsImage")
            //     break;
            // case "गोपनीयता सेटिंग्स को कैसे बदलें":
            //     return navigate("/itemsImage")
            //     break;
            // case "Website credibility":
            //     return navigate("/web-credibility")
            //     break;
            default:
                // console.log("itemsImagePdf true")
                return navigate('/module/items', { state: { url: item.url, title: item.title[language], url2: url } })
        }


    }
    return (
        <div id="body_bg">

            <Header page_title={page_title ? page_title[language] : ""}  />
            <LanguageSet />

            {module &&
                <><div className="container-fluid px-0">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-12">
                            <section className="my-3">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="sectionsBox">
                                                <p className="mb-0 text-center">{module.course_summary.title[language]}</p>
                                            </div>
                                        </div>
                                        <div className="col-12  mt-4">
                                            <div className="contentbox mb-3 mt-1" style={{ minHeight: "120px" }}>
                                                {parse(module.course_summary.description[language])}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="progressBox">
                                                <div className="task-progress pb-3">
                                                    <p>{module.course_summary.title[language]}</p>
                                                    <div className="progresssteps d-flex gap-1">
                                                        <ProgressBarPrec progress={progres} />
                                                    </div>
                                                </div>
                                                <div
                                                    className="start-pro mb-2 d-flex justify-content-between align-items-center"
                                                >
                                                    <div className="p">
                                                        {language === "english" && <p className="mb-0 number-title">{digitalSeafty.points.title[language]} {module.course_summary.title[language]}</p>}
                                                        {language === "hindi" && <p className="mb-0 number-title"> {module.course_summary.title[language]} {digitalSeafty.points.title[language]} </p>}
                                                    </div>
                                                    <div className="startRating d-flex  align-items-center justify-content-center">
                                                        <h4 className="mb-0 text-center">{module.course_summary.points}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <div className="my-2">
                                                <h5 className=" module_title d-inline">{language === "english" ? "Module" : "मॉड्यूल"}</h5>
                                                {/* {sectionStatus === 0 ? <div style={{ cursor: "pointer" }} className="me-2 mx-2 d-inline " data-toggle="tooltip" data-html="true" title="Complete Digital Safety first!" onClick={handleShow}><FontAwesomeIcon icon={faInfoCircle} size="lg" color="#ff0000" className="me-2 mx-2 d-inline" /></div> : ""} */}
                                            </div>

                                            {module.course_module.map((item, index) => {
                                                return (
                                                    <div onClick={() => { if (sectionStatus === 1) { SetNavigate(item, index) } else { handleShow() } }} className="varients" key={index}>
                                                       {/*<div onClick={() => { SetNavigate(item, index) }} className="varients" key={index}>*/}  
                                                        <div className="checkatb d-flex justify-content-between align-items-center mb-2" >
                                                            <div className="tabText">
                                                                <p> <img src={item.icon_path} alt="" className="me-2" width={20} />
                                                                    {/* {(index + 1) + " "} */}
                                                                    {item.title[language]}</p>
                                                            </div>
                                                            {/* <div>{item.total_item }</div>||<div>{item.total_item_marked }</div> */}
                                                            {calculateMarking(item.total_item, item.total_item_marked, module.course_summary.no_modules) ?
                                                                <div className="right_ic">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008000" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                                                    </svg>
                                                                </div>
                                                                :
                                                                <div className="right_ic">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#454545" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <QuizGuid />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                    <Modal show={show} onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            {language === "english" ? "first Complete Digital Safety!" : "पहले डिजिटल सुरक्षा पूरा करें!"}
                        </Modal.Body>
                    </Modal>
                </>
            }
            {loading && <div className="d-flex justify-content-center align " style={{ height: height - 200 }}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
        </div>
    )
}

export default DigitalWellBeing