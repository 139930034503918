import React, { useContext, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useCallback, useRef, useState } from 'react';
import LanguageContext from './hooks/LanguageContext';
import parse from 'html-react-parser';
import { Modal } from 'react-bootstrap';
import ModalInstruction from './ModalInstruction';

const VideoPlayer = ({ question, setMinTime, played, setPlayed, setCount, count }) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const playerRef = useRef();
    const { language } = useContext(LanguageContext);
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // setVideoTotalTime(question.min_duration)
        let minTime = Math.round((Number(question.min_duration) * 65) / 100)
        // console.log(minTime)
        setMinTime(minTime)


    }, [])
    const onReady = useCallback(() => {
        const timeToStart = (7 * 60) + 12.6;
        playerRef.current.seekTo(timeToStart, 'seconds');
    }, [playerRef.current]);

    useEffect(() => {
        setCount(count + 1);
    }, [Math.round(played)])

    useEffect(() => {
        // console.log(Math.round(played))

        setCount(0);
        setPlayed(0)

    }, [language])
    // console.log(played)
    return (
        <>
            <div className='justify-content-end d-flex' >
                <div onClick={handleShow}>
                    <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="sectionsBox">
                        <p className="mb-0 text-center">{question.title[language]}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* <p>{`${question.video_link[language]}-U`}</p> */}
                    <div className="my-3 d-flex justify-content-center">

                        <ReactPlayer
                            url={`${question.video_link[language]}-U`}
                            playing={isPlaying}
                            width="640px"
                            height='360px'
                            controls={true}
                            ref={playerRef}
                            onProgress={(progress) => {
                                setPlayed(progress.playedSeconds);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 ">
                {question.description[language].length > 0 && <div className="contentbox mb-4">
                    <div className="content_safty ">
                        {parse(question.description[language])}
                    </div>
                </div>
                }
            </div>
            {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {parse(question.instruction[language])}
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
            </Modal> */}
            <ModalInstruction show={show} handleClose={handleClose} inst={parse(question.instruction[language])} />
        </>
    )
}

export default VideoPlayer