// src/App.js
import React, { useContext, useEffect, useState, useRef } from 'react';
import '../App.css';
import LanguageContext from './hooks/LanguageContext';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import ModalInstruction from './ModalInstruction';


const Ikigai = ({ isMarked, question, textInputs, setTextInputs, textInputsAns, setTextInputsAns }) => {
    // console.log(textInputs)
    const { language } = useContext(LanguageContext);
    const textOptions = question?.question_option;
    const captureRef = useRef(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // console.log(textInputsAns)
        if (isMarked === 1) {
            setTextInputs(textInputsAns)
        }

    }, [isMarked, textInputsAns])
    // Create a generic event handler to update the state
    const handleInputChange = (e, num) => {
        const { name, value } = e.target;
        // console.log()
        setTextInputs((prevTextValue) =>
            prevTextValue.map((item) =>
                item.id === Number(num) ? { ...item, text: value } : item
            )
        );
        setTextInputsAns((prevTextValue) =>
            prevTextValue.map((item) =>
                item.id === Number(num) ? { ...item, text: value } : item
            )
        );
    };
    // console.log(isMarked)
    const handleCapture = () => {
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png');
            // You can save the 'image' data URL or display it as an image in your app.
            // Create a download link
            const a = document.createElement('a');
            a.href = image;
            a.download = 'captured_image.png';

            // Trigger a click event on the link to prompt the user to save the image
            a.click();
        });
    };
    return (
        <>
            <div className='justify-content-end d-flex' >

                {/* <div data-bs-toggle="modal" data-bs-target="#exampleModal"> */}
                <div onClick={handleShow}>
                    <img src={require("../assets/images/instructions.png")} className='mobielViewIcon' />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="sectionsBox d-flex justify-content-between" style={{ display: "flex", }}>
                        <div></div>
                        <p className="mb-0 text-center" style={{ marginLeft: 10 }}>{language === "english" ? "IKIGAI " : "इकिगाई"}</p>
                        <div style={{ marginRight: 10 }} onClick={handleCapture} ><FontAwesomeIcon icon={faDownload} size="lg" color="#fff" /></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 ">
                    <div className="contentbox mb-2 mt-3">
                        <div className="content_safty ">
                            {language === "english" ? `Take a few minutes to think about what you love, what you're good at doing, how you can help others, and what you could do online. Imagine where these things come together – one common answer to all these questions - that's your "Internet Ikigai," your special online purpose. Answer the 4 questions in the picture to find your Internet Ikigai. Have fun exploring!` : `कुछ मिनट लें और सोचें कि आप क्या पसंद करते हैं, आप किसमें माहिर हैं, आप दूसरों की कैसे मदद कर सकते हैं, और आप ऑनलाइन क्या कर सकते हैं। कल्पना करें कि ये सब कहाँ मिलते हैं – इन सभी सवालों का जो एक जवाब होगा वही आपकी "इंटरनेट इकिगाई," आपका खास ऑनलाइन उद्देश्य है।चित्र में दिए गए 4 सवालों के उत्तर देकर अपनी इंटरनेट इकिगाई खोजें। खुशियों से खोजने का आनंद लें!`}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" ref={captureRef}>
                <div className="col-12 ">
                    <div className="mb-2 mt-3 position-relative set_height">
                        <div className='cercleBoxes'>
                            <div className="circle circle1"></div>
                            <div className="circle circle2"></div>
                            <div className="circle circle3"></div>
                            <div className="circle circle4"></div>
                            {
                                textOptions?.map((item, index) => (
                                    <span className={`intersect-text text-center${index + 1}`} key={index}>{item.title[language]}</span>
                                ))
                            }
                            {/* <span className='intersect-text text-center1 '> {language === "english" ? "PASSION" : "जुनून"}</span>
                            <span className='intersect-text text-center2' > {language === "english" ? "MISSION" : "लक्ष्य"}</span>
                            <span className='intersect-text text-center3'> {language === "english" ? "VOCATION" : "पेशा"}</span>
                            <span className='intersect-text text-center4'> {language === "english" ? "PROFESSION" : "व्यवसाय"}</span>
                            <span className='intersect-text text-center5'> {language === "english" ? "IKIGAI" : "इकिगाई"}</span> */}
                            {textInputs?.map((item, index) => (
                                <div className={`TextInput${index + 1}`} key={item.id}>
                                    <textarea
                                        name={index + 1}
                                        className={`text-input${(index === 0 || index === 3) ? "" : "-even"}`}
                                        placeholder={item?.title[language]}
                                        style={{ backgroundColor: "transparent", fontSize: "10px", textAlign: "justify", textAlignLast: "center", whiteSpace: "normal" }}
                                        value={item?.text}
                                        onChange={(e) => { handleInputChange(e, (index + 1)) }}
                                        disabled={isMarked === 1 ? true : false}
                                    />
                                </div>
                            ))}
                            {/* <div className='TextInput1'>
                                <textarea className='text-input' placeholder={language === "english" ? "What do you love doing?" : "आपको क्या पसंद है"} style={{ backgroundColor: "transparent", fontSize: "10px", textAlign: "justify", textAlignLast: "center", whiteSpace: "normal" }} />
                            </div>
                            <div className='TextInput2'>
                                <textarea className='text-input-even' placeholder={language === "english" ? "What does the world need?" : "दुनिया को किस चीज की जरूरत है"} style={{ backgroundColor: "transparent", fontSize: "10px", textAlign: "justify", textAlignLast: "center", whiteSpace: "normal" }} />
                            </div>
                            <div className='TextInput3'>
                                <textarea className='text-input-even' placeholder={language === "english" ? "What can you be paid for?" : "आप किस कार्य से पैसे कमा सकते हैं"} style={{ backgroundColor: "transparent", fontSize: "10px", textAlign: "justify", textAlignLast: "center", whiteSpace: "normal" }} />
                            </div>
                            <div className='TextInput4'>
                                <textarea className='text-input' placeholder={language === "english" ? "What are you good at?" : "आप किस कार्य में अच्छे हैं"} style={{ backgroundColor: "transparent", fontSize: "10px", textAlign: "justify", textAlignLast: "center", whiteSpace: "normal" }} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <ModalInstruction show={show} handleClose={handleClose} inst={language === "english" ? "To complete the activity, answer the 4 questions in the image. Click mark as done to submit your answer" : "क्रिया पूर्ण करने के लिए, चित्र में दिए गए 4 प्रश्नों का उत्तर दें। अपना उत्तर सबमिट करने के लिए 'मार्क अस डन' पर क्लिक करें।"} />

        </>
    );
};

export default Ikigai;
