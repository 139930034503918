import React, { Component } from "react";
import "./css/board.css";
import "./css/buttons.css";
import Player1 from "./Player1";
import Dice from "./Dice";
import position from "./Position";
import Swal from 'sweetalert2'
import DiceRoller from "./DiceRoller";
import snake from "./images/snakeAlert.png";
import ladder from "./images/ladderAlert.png";
import ModalInstruction from "../ModalInstruction";
import parse from 'html-react-parser';

class Game extends Component {

  constructor(props) {
    super(props);
    // console.log(props.language)
  }
  state = {
    player1Pos: 0,
    currentPlayer: "player1",
    positionObj: position,
    ladderStart: 0,
    snakePosition: 0,
    diceValue: null,
    rolling: false, // Add a rolling state variable
    playerWon: false,
    show: false,
  }

  ChangeDiceValue = () => {

    if (this.state.rolling) return; // Prevent rolling while already rolling

    // Set rolling to true
    this.setState({ rolling: true });
    let rand = Math.floor(Math.random() * ((6 - 1) + 1) + 1);
    // Simulate dice rolling with a timeout
    setTimeout(() => {

      this.setState({ diceValue: rand, rolling: false });
      // let rand = 1;
      setTimeout(() => {
        let pos = 0;
        let snakeNumber = 0;

        let val = this.state.player1Pos + rand;

        if (val > 100) {
          val = 100; // Ensure the player doesn't go beyond position 100
        }

        snakeNumber = this.state.player1Pos + rand;
        pos = this.state.positionObj[val];
        // console.log(pos)
        this.setState({
          diceValue: rand,
          player1Pos: pos,
          ladderStart: val,
          snakePosition: snakeNumber
        }, () => {
          this.playerWon();
          this.snakeBite();
        });
      }, 1500)

    }, 1000);


  }


  playerWon() {

    let language = this.props.language
    switch (this.state.ladderStart) {
      case 3:
        if (this.state.ladderStart === 3) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  <p class="alert-text">
                  ${language === "english" ?
                "You understand and use strong passwords for all your accounts. Great job, you have taken a strong step towards safe and responsible internet use! "
                : "आप अपने सभी खातों के लिए मजबूत पासवर्ड बनाते हैं और उनका उपयोग करते हैं। बहुत बढ़िया आपने सुरक्षित और जिम्मेदार इंटरनेट उपयोग की दिशा में एक मजबूत कदम उठाया है!"}
                  </p>
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });

        }
        break;
      case 12:
        if (this.state.ladderStart === 12) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  <p class="alert-text">
                  ${language === "english" ?
                "Good Job, you have limited the time you spend online. By understanding the importance of balance, you are building a healthy relationship with social media, and you know how to take care of your mood, emotions and health! "
                : "बहुत बढ़िया, आपने ऑनलाइन बिताये जाने वाले  समय पर नियंत्रण रखा है। मानसिक संतुलन के महत्व को समझते हुए, आप सोशल मीडिया के साथ एक स्वस्थ संबंध बना रहे हैं, और आप जानते हैं कि अपनी मनोदशा, भावनाओं और स्वास्थ्य का ख्याल कैसे रखना है!"
              }
                  </p>
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }

        break;



      case 21:
        if (this.state.ladderStart === 21) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  ${language === "english" ?
                "Oh wow! You have been participating in online quizzes, safe games and healthy discussions online. As a result, you have learnt a lot and had a lot of fun online. You also feel closer to your friends who play with you. What a great way to use the internet!"
                : "अरे वाह! आप ऑनलाइन पहेलियाँ, सुरक्षित गेम और अच्छी ऑनलाइन चर्चाओं में भाग ले रहे हैं। इस वजह से, आपने बहुत कुछ सीखा है और ऑनलाइन खूब आनंद उठाया है। आप अपने उन दोस्तों के भी करीब महसूस करते हैं जो आपके साथ खेलते हैं। इंटरनेट का उपयोग करने का क्या बढ़िया तरीका है!"
              }
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });

        }
        break;
      case 34:
        if (this.state.ladderStart === 34) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  ${language === "english" ?
                "Well done, you have used the internet to help other people around you. Your contributions on the internet are bringing about positive social change."
                : "शाबाश, आपने अपने आस-पास के अन्य लोगों की मदद करने के लिए इंटरनेट का उपयोग किया है। इंटरनेट पर आपका योगदान समाज की बेहतरी में मदद कर रहें हैं । "
              }
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });

        }
        break;
      case 46:
        if (this.state.ladderStart === 46) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  ${language === "english" ?
                "You have been learning new skills and information online. You have used online platforms for personal growth. That’s great! "
                : "आप ऑनलाइन नई चीज़ें और जानकारी सीख रहे हैं। आपने स्वयं के विकास के लिए ऑनलाइन प्लेटफ़ॉर्म का उपयोग किया है। यह बहुत अच्छा है!"
              }
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });

        }
        break;
      case 58:
        if (this.state.ladderStart === 58) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  ${language === "english" ?
                "You’re doing your best to have a healthy relationship with technology, and have good friendships online. That’s great"
                : "आप टेक्नोलॉजी के साथ स्वस्थ संबंध बनाने और ऑनलाइन अच्छी मित्रता बनाने की पूरी कोशिश कर रहे हैं। यह बहुत अच्छा है!"
              }
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }

        break;

      case 67:
        if (this.state.ladderStart === 67) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  ${language === "english" ?
                "You’re doing your best to have a healthy relationship with technology, and have good friendships online. That’s great! "
                : "आप टेक्नोलॉजी के साथ स्वस्थ संबंध बनाने और ऑनलाइन अच्छी मित्रता बनाने की पूरी कोशिश कर रहे हैं। यह बहुत अच्छा है!"
              }
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
        }

        break;

      case 71:
        if (this.state.ladderStart === 71) {
          Swal.fire({
            html: `
            <div>
               <p class="alert-heading1">${language === "english" ? "You get to climb the Ladder!" : "आपको सीढ़ी पर चढ़ना होगा!"} </p>
               <div class="d-flex ">
                  <div class="alert-image">
                     <img class="alert-image-tag" src=${ladder} alt="snake">
                  </div>
                  <div >
                  ${language === "english" ?
                "Oh wow! You’ve been making sure that you report harmful activities by other users on social media platforms. By making this a habit, you’re trying to make the internet a safe place, not only for you, but also for everyone else! "
                : "अरे वाह! आप आप सोशल मीडिया प्लेटफ़ॉर्म पर अन्य लोगों  द्वारा की गई हानिकारक क्रियाओं को रिपोर्ट करने का प्रयास कर रहे है। इसे एक आदत बनाकर, आप इंटरनेट को न केवल अपने लिए, बल्कि बाकी सभी के लिए एक सुरक्षित स्थान बनाने का प्रयास कर रहे हैं!"
              }
                  </div>
               </div>
            </div>`,
            animation: false,
            showCancelButton: false,
            showConfirmButton: false,
          });

        }
        break;
      case 100:
        //change button state if ladder on 100
        this.props.onStateChange(true)
        Swal.fire({
          text: '100: YOU WON!',
          animation: false,
          position: 'top',
          confirmButtonText: 'OK',
          confirmButtonColor: '#792110',
        });
        break;
      default: return null
    }

  }
  snakeBite() {
    // console.log("snakePosition pos", this.state.snakePosition)
    let language = this.props.language
    switch (this?.state?.snakePosition) {
      case 16:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "Uh oh! You’ve been setting weak passwords, and using the same password for different accounts! Now, your personal accounts could be hacked; the hacker could change your password, and you won’t be able to log in."
              : "उफ्फोह! आप कमज़ोर पासवर्ड सेट कर रहे हैं, और विभिन्न खातों के लिए एक ही पासवर्ड का उपयोग कर रहे हैं! अब, आपके निजी खाते हैक हो सकते हैं; हैकर आपका पासवर्ड बदल सकता है, और आप लॉग इन नहीं कर पाएंगे।"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      case 22:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "That’s not nice! You have used hurtful words online or on chats. There’s another human being across the other screen, and when you’re unkind, you lose your progress."
              : "यह अच्छी बात नहीं है! आपने ऑनलाइन या चैट पर नुक्सान पहुँचाने वाले शब्दों का प्रयोग किया है। दूसरी स्क्रीन पर एक और इंसान है, और जब आप उनके प्रति कठोर शब्दों का इस्तेमाल करते हैं , तो आप अपनी प्रगति खो देते हैं।"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      case 32:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "Some people are harassing and scaring you online. This bullying is harmful for you! Now, you often feel sad and demotivated. You don’t have energy to do your other tasks. Your mood is badly affected. Your digital safety and well-being have been brought down by others’ actions."
              : "कुछ लोग आपको ऑनलाइन परेशान कर रहे हैं और डरा रहे हैं। यह बदमाशी आपके लिए हानिकारक है! अब, आप अक्सर उदास और निराश महसूस करते हैं। आपके पास अपने अन्य कार्य करने की शक्ति  नहीं है। आपका मन बुरी तरह प्रभावित होता है. आपकी डिजिटल सुरक्षा और देखभाल दूसरों के कार्यों के कारण प्रभावित हुई है।"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      case 61:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "Danger! You’ve entered an unsafe site, which may contain viruses or malware. By clicking on a suspicious link without verifying it, you’ve made a choice that could harm you or your device."
              : "खतरा! आप एक असुरक्षित वेबसाइट पर हैं, जिसमें वायरस या मैलवेयर हो सकते हैं। किसी अंजान लिंक के जांच किए बिना उस पर क्लिक करके, आपने ऐसा विकल्प   चुना है जो आपको या आपके डिवाइस को नुकसान पहुंचा सकता है।"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;

      case 64:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "Oops! You’ve been visiting unsafe websites. Because of this, unknown viruses  have entered your device, weakening your online security! Sorry, you’ll need to go back several steps in the game!"
              : "उफ़! आप असुरक्षित वेबसाइट पर जा रहे हैं। इस वजह से, अज्ञात वायरस आपके डिवाइस में प्रवेश कर गए हैं, जिससे आपकी ऑनलाइन सुरक्षा कमजोर हो गई है! क्षमा करें, आपको खेल में कई कदम पीछे जाना होगा!"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      case 72:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "You have been playing online games for hours at a time. Looking at a screen for so long has worsened your eyesight. Your eyes are also often watering and distressed, causing discomfort."
              : "आप लगातार घंटों तक ऑनलाइन गेम खेल रहे हैं। इतनी देर तक स्क्रीन देखने से आपकी आंखों की रोशनी खराब हो गई है। आपकी आंखों में भी अक्सर पानी आता रहता है जिससे आपको परेशानी हो रही है।"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;

      case 87:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "Nooo! Have you shared your e-mail ID or photograph on unknown apps and websites? It may not be safe – your data could reach strangers."
              : "नहीं! क्या आपने अपनी ई-मेल आईडी या तस्वीर अनजान  ऐप्स और वेबसाइटों पर साझा की है? यह सुरक्षित नहींहै - आपका डेटा अजनबियों तक पहुंच सकता है।"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;


      case 98:
        Swal.fire({
          html: `
          <div>
             <p class="alert-heading1">${language === "english" ? "You got bit by a Snake!" : "तुम्हें साँप ने काट लिया है!"} </p>
             <div class="d-flex ">
                <div class="alert-image">
                   <img class="alert-image-tag" src=${snake} alt="snake">
                </div>
                <div >
                ${language === "english" ?
              "Sorry, you can’t move forward! You have accepted friend requests from strangers on social media. You do not know that person, and they can now see who you are and what you post… Your safety may be threatened, online and offline. The privacy and comfort that you had carefully built online is now weaker!"
              : "माफ़ी चाहते है, आप आगे नहीं बढ़ सकते! आपने सोशल मीडिया पर अजनबियों की  फ्रेंड रिक्वेस्टस्वीकार कर ली  है । आप उस व्यक्ति को नहीं जानते हैं, और वे अब देख सकते हैं कि आप कौन हैं और आप क्या पोस्ट करते हैं... आपकी ऑनलाइन  और ऑफलाइन सुरक्षा को खतरा हो सकता है। जिस गोपनीयता और सुरक्षा को आपने सावधानी से ऑनलाइन हासिल किया था वह अब कमज़ोर हो गई   है!"
            }
                </div>
             </div>
          </div>`,
          animation: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      default: return null
    }
  }
  newGame = () => {
    this.setState({
      player1Pos: 0,
      snakePosition: 0,
      currentPlayer: "player1",
      positionObj: position,
      diceValue: null
    });
  }

  setPlayersPos = (num) => {
    if (this.state.player1Pos == num) return (<Player1 />)
    return null
  }

  handleShow = () => {
    this.setState({
      show: true
    })
  }

  handleClose = () => {
    this.setState({
      show: false
    })
  }

  render() {
    return (
      <>
        <div className='justify-content-end d-flex' >
          <div onClick={this.handleShow}>
            <img src={require("../../assets/images/instructions.png")} alt="instruction" className='mobielViewIcon' />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="sectionsBox">
              <p className="mb-0 text-center">{this?.props?.question?.title[this?.props?.language]}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">

            <div className="contentbox mb-2 mt-3">
              <div className="content_safty ">
                {/* {parse(question.question_title[language])} */}
              </div>
              <div className="content_safty ">
                {parse(this?.props?.question?.description[this?.props?.language])}
              </div>
            </div>

          </div>
        </div>

        <div className="flex-parent">
          <div className="board-parent">
            <div className="board-image"></div>
            <div className="board-wrap">
              <div className="board" id="100">100{this.setPlayersPos(100)}</div>
              <div className="board" id="99">99{this.setPlayersPos(99)}</div>
              <div className="board" id="98">98{this.setPlayersPos(98)}</div>
              <div className="board" id="97">97{this.setPlayersPos(97)}</div>
              <div className="board" id="96">96{this.setPlayersPos(96)}</div>
              <div className="board" id="95">95{this.setPlayersPos(95)}</div>
              <div className="board" id="94">94{this.setPlayersPos(94)}</div>
              <div className="board" id="93">93{this.setPlayersPos(93)}</div>
              <div className="board" id="92">92{this.setPlayersPos(92)}</div>
              <div className="board" id="91">91{this.setPlayersPos(91)}</div>
              <div className="board" id="81">81{this.setPlayersPos(81)}</div>
              <div className="board" id="82">82{this.setPlayersPos(82)}</div>
              <div className="board" id="83">83{this.setPlayersPos(83)}</div>
              <div className="board" id="84">84{this.setPlayersPos(84)}</div>
              <div className="board" id="85">85{this.setPlayersPos(85)}</div>
              <div className="board" id="86">86{this.setPlayersPos(86)}</div>
              <div className="board" id="87">87{this.setPlayersPos(87)}</div>
              <div className="board" id="88">88{this.setPlayersPos(88)}</div>
              <div className="board" id="89">89{this.setPlayersPos(89)}</div>
              <div className="board" id="90">90{this.setPlayersPos(90)}</div>
              <div className="board" id="80">80{this.setPlayersPos(80)}</div>
              <div className="board" id="79">79{this.setPlayersPos(79)}</div>
              <div className="board" id="78">78{this.setPlayersPos(78)}</div>
              <div className="board" id="77">77{this.setPlayersPos(77)}</div>
              <div className="board" id="76">76{this.setPlayersPos(76)}</div>
              <div className="board" id="75">75{this.setPlayersPos(75)}</div>
              <div className="board" id="74">74{this.setPlayersPos(74)}</div>
              <div className="board" id="73">73{this.setPlayersPos(73)}</div>
              <div className="board" id="72">72{this.setPlayersPos(72)}</div>
              <div className="board" id="71">71{this.setPlayersPos(71)}</div>
              <div className="board" id="61">61{this.setPlayersPos(61)}</div>
              <div className="board" id="62">62{this.setPlayersPos(62)}</div>
              <div className="board" id="63">63{this.setPlayersPos(63)}</div>
              <div className="board" id="64">64{this.setPlayersPos(64)}</div>
              <div className="board" id="65">65{this.setPlayersPos(65)}</div>
              <div className="board" id="66">66{this.setPlayersPos(66)}</div>
              <div className="board" id="67">67{this.setPlayersPos(67)}</div>
              <div className="board" id="68">68{this.setPlayersPos(68)}</div>
              <div className="board" id="69">69{this.setPlayersPos(69)}</div>
              <div className="board" id="70">70{this.setPlayersPos(70)}</div>
              <div className="board" id="60">60{this.setPlayersPos(60)}</div>
              <div className="board" id="59">59{this.setPlayersPos(59)}</div>
              <div className="board" id="58">58{this.setPlayersPos(58)}</div>
              <div className="board" id="57">57{this.setPlayersPos(57)}</div>
              <div className="board" id="56">56{this.setPlayersPos(56)}</div>
              <div className="board" id="55">55{this.setPlayersPos(55)}</div>
              <div className="board" id="54">54{this.setPlayersPos(54)}</div>
              <div className="board" id="53">53{this.setPlayersPos(53)}</div>
              <div className="board" id="52">52{this.setPlayersPos(52)}</div>
              <div className="board" id="51">51{this.setPlayersPos(51)}</div>
              <div className="board" id="41">41{this.setPlayersPos(41)}</div>
              <div className="board" id="42">42{this.setPlayersPos(42)}</div>
              <div className="board" id="43">43{this.setPlayersPos(43)}</div>
              <div className="board" id="44">44{this.setPlayersPos(44)}</div>
              <div className="board" id="45">45{this.setPlayersPos(45)}</div>
              <div className="board" id="46">46{this.setPlayersPos(46)}</div>
              <div className="board" id="47">47{this.setPlayersPos(47)}</div>
              <div className="board" id="48">48{this.setPlayersPos(48)}</div>
              <div className="board" id="49">49{this.setPlayersPos(49)}</div>
              <div className="board" id="50">50{this.setPlayersPos(50)}</div>
              <div className="board" id="40">40{this.setPlayersPos(40)}</div>
              <div className="board" id="39">39{this.setPlayersPos(39)}</div>
              <div className="board" id="38">38{this.setPlayersPos(38)}</div>
              <div className="board" id="37">37{this.setPlayersPos(37)}</div>
              <div className="board" id="36">36{this.setPlayersPos(36)}</div>
              <div className="board" id="35">35{this.setPlayersPos(35)}</div>
              <div className="board" id="34">34{this.setPlayersPos(34)}</div>
              <div className="board" id="33">33{this.setPlayersPos(33)}</div>
              <div className="board" id="32">32{this.setPlayersPos(32)}</div>
              <div className="board" id="31">31{this.setPlayersPos(31)}</div>
              <div className="board" id="21">21{this.setPlayersPos(21)}</div>
              <div className="board" id="22">22{this.setPlayersPos(22)}</div>
              <div className="board" id="23">23{this.setPlayersPos(23)}</div>
              <div className="board" id="24">24{this.setPlayersPos(24)}</div>
              <div className="board" id="25">25{this.setPlayersPos(25)}</div>
              <div className="board" id="26">26{this.setPlayersPos(26)}</div>
              <div className="board" id="27">27{this.setPlayersPos(27)}</div>
              <div className="board" id="28">28{this.setPlayersPos(28)}</div>
              <div className="board" id="29">29{this.setPlayersPos(29)}</div>
              <div className="board" id="30">30{this.setPlayersPos(30)}</div>
              <div className="board" id="20">20{this.setPlayersPos(20)}</div>
              <div className="board" id="19">19{this.setPlayersPos(19)}</div>
              <div className="board" id="18">18{this.setPlayersPos(18)}</div>
              <div className="board" id="17">17{this.setPlayersPos(17)}</div>
              <div className="board" id="16">16{this.setPlayersPos(16)}</div>
              <div className="board" id="15">15{this.setPlayersPos(15)}</div>
              <div className="board" id="14">14{this.setPlayersPos(14)}</div>
              <div className="board" id="13">13{this.setPlayersPos(13)}</div>
              <div className="board" id="12">12{this.setPlayersPos(12)}</div>
              <div className="board" id="11">11{this.setPlayersPos(11)}</div>
              <div className="board" id="1">1{this.setPlayersPos(1)}</div>
              <div className="board" id="2">2{this.setPlayersPos(2)}</div>
              <div className="board" id="3">3{this.setPlayersPos(3)}</div>
              <div className="board" id="4">4{this.setPlayersPos(4)}</div>
              <div className="board" id="5">5{this.setPlayersPos(5)}</div>
              <div className="board" id="6">6{this.setPlayersPos(6)}</div>
              <div className="board" id="7">7{this.setPlayersPos(7)}</div>
              <div className="board" id="8">8{this.setPlayersPos(8)}</div>
              <div className="board" id="9">9{this.setPlayersPos(9)}</div>
              <div className="board" id="10">10{this.setPlayersPos(10)}</div>
            </div>
          </div>
          <div className="dicePosition">
            {/* < ShowDice diceValue={this.state.diceValue} /> */}
            <DiceRoller diceValue={this.state.diceValue} rolling={this.state.rolling} />
          </div>
          <div className="buttonPosition" style={{ gap: 40, marginBottom: 20 }}>
            < Dice diceValue={this.ChangeDiceValue} rolling={this.state.rolling} />
            {/* < NewGame newGame={this.newGame} /> */}

          </div>

        </div>

        <ModalInstruction show={this.state.show} handleClose={this.handleClose} inst={parse(this?.props?.question?.instruction[this?.props?.language])} />
      </>

    )
  }
}

export default Game;

