import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import parse from 'html-react-parser';
const  MyVerticallyCenteredModalResource =(props)=> {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton className='border-0 py-0'>
          <Modal.Title className="ms-auto" id="contained-modal-title-vcenter" >
            {props.title}
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className='justify-content-center d-flex'>
          {/* {props.modalItemType === "video"}
          <img src={props.imageLink} alt="Modal Content" /> */}
          <div>
             {parse(props.imageLink)}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  
  export default MyVerticallyCenteredModalResource