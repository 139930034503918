import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import LanguageSet from "../components/language/LanguageSet"

const ThankYou = () => {
    const navigate = useNavigate()
    return (
        <div id="body_bg">
            <Header page_title={""}  />
            <LanguageSet />

            <div className="container-fluid px-0">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-md-12">
                        <section className="my-3">
                            <div className="container-fluid">

                                        <div className="row">
                                    <div className="col-12">
                                 
                                        <div className="thanksmsg my-4">
                                            <img src={require("../assets/images/thankyou1.png")} alt="" width="100%"  />
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="submit_section d-flex justify-content-center gap-4">
                                        <div className="back">
                                        <div onClick={()=>{navigate(-1)}} className="back-btn">Back</div>
                                        </div>
                                        <div className="back">
                                            <div onClick={()=>{navigate("/home")}} className="submit-btn">Next</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ThankYou